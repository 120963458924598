export const generateDeliveryOption = (address) => {
  let newDeliveryAddressOption = [];

  if (address) {
    if (address.delivery_address_line_1) {
      newDeliveryAddressOption.push({
        id: 0,
        address_name: 'Default',
        address_line_1: address.delivery_address_line_1 ?? '',
        address_line_2: address.delivery_address_line_2 ?? '',
        city: address.delivery_city ?? '',
        state: address.delivery_state ?? '',
        post_code: address.delivery_post_code ?? '',
        lable: 
          `${address.delivery_address_line_1? address.delivery_address_line_1 : ''}` +
          `${address.delivery_address_line_2 ? ', ' + address.delivery_address_line_2 : ''}` +
          `${address.delivery_city ? ', ' + address.delivery_city + ' ' : ''}` +
          `${address.delivery_state ? address.delivery_state + ' ' : ''}` +
          `${address.delivery_post_code ? address.delivery_post_code + ' ' : ''}`
      })
    }
    
    if (address.additional_address && address.additional_address.length > 0) {
      address.additional_address.map(data => {
        newDeliveryAddressOption.push({
          id: data.id,
          address_name: data.address_name,
          address_line_1: data.address_line_1 ?? '',
          address_line_2: data.address_line_2 ?? '',
          city: data.city ?? '',
          state: data.state ?? '',
          post_code: data.post_code ?? '',
          lable: 
            `${data.address_line_1? data.address_line_1 : ''}` +
            `${data.address_line_2 ? ', ' + data.address_line_2 : ''}` +
            `${data.city ? ', ' + data.city + ' ' : ''}` +
            `${data.state ? data.state + ' ' : ''}` +
            `${data.post_code ? data.post_code + ' ' : ''}`
        })
      });
    }
  }
  
  newDeliveryAddressOption.push({
    id: -1,
    address_name: 'Custom',
    lable: 'Custom',
  })

  return newDeliveryAddressOption;
}