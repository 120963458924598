import React, { useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

const ResetPassword = () => {
  const [company, setCompany] = useState('');
  const [content, setContent] = useState('');

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const {name, value} = e.target;

    if(name === "company")
    {
      setCompany(value);
    }
    else if(name === "content")
    {
      setContent(value);
    }

  }

  const send = () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    var react_app_url = process.env.REACT_APP_URL;
    if(window.location.hostname.search('interax')!==-1){
      react_app_url = process.env.REACT_APP_URL_INTERAX;
    }

    fetch(`${react_app_url}${company}/custom/passwordreset/${content}`, requestOptions)
    .then(res => console.log(res));
    handleClose();
  }

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        Forgot Password
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={'sm'} fullWidth={true}>
        <DialogTitle id="form-dialog-title">Forgot Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="company"
            name="company"
            label="Company"
            type="text"
            variant="outlined"
            value={company}
            onChange={(e)=>handleChange(e)}
            fullWidth
          />
          <TextField
            margin="dense"
            id="content"
            name="content"
            label="Email Address/ Phone"
            type="email"
            variant="outlined"
            value={content}
            onChange={(e)=>handleChange(e)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={send} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ResetPassword;
