import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete';
import useStateRef from "react-usestateref";
import EventEmitter from "src/utils/EventEmitter";
import directus from "../../services/directus";
import moment from "moment";
import Enumerable from "linq";
import linq from 'linq';
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from 'notistack';
import { ThemeProvider } from '@material-ui/core/styles';
import ErrorMessage from 'src/components/ErrorMessage';
import { AdminPermission, InvoiceAdminPermission, POApproverPermission, POCreatorPermission } from 'src/utils/Permission';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
import { getCache, setCache } from 'src/utils/KeyValueCache';
import { OverflowTip } from "src/components/OverflowTip";
import { IconButton, Divider, Skeleton, Box } from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import Autocomplete from '@mui/material/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  theme,
  useStyles,
  getStatusIconPath,
  AddItemButton,
  NumberFormatCustom,
  NumberFormatCustomPercent,
  filterOptions,
  filterOptionsItemNumber,
  filterOptionsDescription,
  filterOption
} from './style.js';
import { generateDeliveryOption } from './ReusableFunction';
import {
  Grid,
  Typography,
  MenuItem,
  TextField,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";
import OrderItemsNotDraftView from './OrderItemsNotDraftView';
import ButtonsView from './ButtonsView';
import AddOrder from './AddOrder';

var react_app_url = process.env.REACT_APP_URL;
if (window.location.hostname.search("interax") !== -1) {
  react_app_url = process.env.REACT_APP_URL_INTERAX;
}

function SelectedOrderView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [initialized, setInitialized] = useState(false);
  const [selectedOrder, setSelectedOrder, selectedOrderRef] = useStateRef({statusIconPath: getStatusIconPath('draft'), status: 'draft'});
  const [orderLoading, setOrderLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [add, setAdd] = useState(false);
  const [openChangeLog, setOpenChangeLog] = useState(false);
  const [showTotalDetails, setShowTotalDetails] = useState(false);
  const [logData, setLogData] = useState([]);
  const [logDataLoading, setLogDataLoading] = useState(false);
  const [orderEventType, setOrderEventType] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isCustomEmail, setIsCustomEmail, isCustomEmailRef] = useStateRef(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [email, setEmail] = useState('');
  const [isClickFavourite, setIsClickFavourite, isClickFavouriteRef] = useStateRef(false);
  const [selectedSupplier, setSelectedSupplier, selectedSupplierRef] = useStateRef(null);

  const [customEmailLoading, setCustomEmailLoading] = useState(false);
  const [dataCustomEmail, setDataCustomEmail, dataCustomEmailRef] = useStateRef({
    send_to_email: null,
    cc_email: null
  });

  const [itemsLoading, setItemsLoading] = useState(false);
  const [itemsOptions, setItemsOptions, itemsOptionsRef] = useStateRef([]);

  const [deliveryAddressName, setDeliveryAddressName] = useState(null);
  const [deliveryAddressLine1, setDeliveryAddressLine1] = useState(null);
  const [deliveryAddressLine2, setDeliveryAddressLine2] = useState(null);

  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [deliveryAddressOptionId, setDeliveryAddressOptionId, deliveryAddressOptionIdRef] = useStateRef(0);
  const [deliveryAddressOptionSelected, setDeliveryAddressOptionSelected] = useState([]);
  const [deliveryAddressOptions, setDeliveryAddressOptions] = useState([{ id: -1, address_name: 'Custom', lable: 'Custom' }]);
  
  const [inputAddressValue, setInputAddressValue, inputAddressValueRef] = useStateRef('');
  const [selectedDeliveryAddress, setSelectedDeliveryAddress, selectedDeliveryAddressRef] = useStateRef({
    AddressLine1: '',
    AddressLine2: '',
    AddressName: '',
    AddressCity: '',
    AddressState: '',
    AddressPostcode: '',
  });

  const [totalOrderItem, setTotalOrderItem, totalOrderItemRef] = useStateRef(0);
  const [subTotal, setSubTotal, subTotalRef] = useStateRef(0);
  const [subTotalIncludingTax, setSubTotalIncludingTax, subTotalIncludingTaxRef] = useStateRef(0);
  const [totalTax, setTotalTax, totalTaxRef] = useStateRef(0);
  const [orderDiscount, setOrderDiscount, orderDiscountRef] = useStateRef(0);
  const [shipping, setShipping, shippingRef] = useStateRef(0);

  const [items, setItems, itemsRef] = useStateRef(null);
  const [itemCheck, setItemCheck] = useState(false);
  const [itemCount, setItemCount, itemCountRef] = useStateRef(false);
  const [loadingItems, setLoadingItems, loadingItemsRef] = useStateRef(false);
  const [maxSort, setMaxSort, maxSortRef] = useStateRef(false);
  
  const [selectedCustomer, setSelectedCustomer, selectedCustomerRef] = useStateRef(null);
  const [customerOptions, setCustomerOptions, customerOptionsRef] = useStateRef([]);
  const [customerLoading, setCustomerLoading] = useState(false);
  
  const [selectedDeliveryDate, handleDeliveryDateChange] = useState(new Date());
  const [deliveryInstructions, setDeliveryInstructions] = useState(null);
  const [notes, setNotes] = useState(null);
  
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadOrderSelected();
      loadOrderEventType();
      loadCustomer();
      loadItems();
      getConfigurationSettings();
    }
  }, []);

  useEffect(() => {
    const changeSelectedOrder = async () => {
      setItemCheck(true);
      setOrderLoading(true);
      await loadOrderSelected();
    };

    const listener1 = EventEmitter.addListener("selected_sales_order", changeSelectedOrder);

    const add = async eventData => {
      navigate(`/sales-orders/new`);
      await loadOrderSelected();
    };
    const listener2 = EventEmitter.addListener("addOrder", add);

    const navigationChanges = async () => {
      let pathname = window.location.pathname;      
      if (pathname.includes('sales-orders')) {
        await loadOrderSelected();
      }
    };

    const listener3 = EventEmitter.addListener('navigation_changes', navigationChanges);

    return () => {
      listener1.remove();
      listener2.remove();
      listener3.remove();
    };
  }, []);

  const loadOrderSelected = async (loadStatus = true) => {
    if (loadStatus) {
      setOrderLoading(true);
    }

    const selectedOrderId = window.location.pathname.split('/').pop();
    if (selectedOrderId !== 'new') {
      const params_filter = [
        "employee.id",
        "employee.owner",
        "employee.position_level",
        "employee.job_title",
        "employee.mobile_number",
        "employee.code",
        "employee.type",
        "employee.customer_assigned_to",
        "employee.email",
        "employee.po_user_role",
        "employee.related",
        "employee.rosters",
        "employee.user.*",
        "farm_code.id",
        "farm_code.code",
        "farm_code.name",
        "farm_code.email",
        "farm_code.status",
        "farm_code.legal_name",
        "farm_code.delivery_address_line_1",
        "farm_code.delivery_address_line_2",
        "farm_code.delivery_city",
        "farm_code.delivery_state",
        "farm_code.delivery_post_code",
        "farm_code.additional_address.*",
        "modified_on",
        "modified_by.*",
        "files.*.*",
        "sales_order_items.sales_order_item.description",
        "sales_order_items.sales_order_item.id",
        "sales_order_items.sales_order_item.internal_code",
        "sales_order_items.sales_order_item.quantity",
        "sales_order_items.sales_order_item.sort",
        "sales_order_items.sales_order_item.unit_price",
        "sales_order_items.sales_order_item.item_discount",
        "sales_order_items.sales_order_item.sales_item.*",
        "sales_order_items.id",
        "id",
        "status",
        "order_date",
        "cc_email",
        "notes",
        "order_number",
        "farm_code",
        "additional_notes",
        "due_date",
        "delivery_instructions",
        "delivery_address_option",
        "delivery_address_name",
        "delivery_address_line_1",
        "delivery_address_line_2",
        "delivery_address_city",
        "delivery_address_state",
        "delivery_address_postcode",
        "send_to_email",
        "order_discount",
        "shipping"
      ];
  
      // get the selected order data
      const orderSelected = await directus.api.post(`/custom/sales_orders?fields=${params_filter}`, {
        filter: {
          "sales_orders.id": selectedOrderId
        }
      });
      
      if (orderSelected.data.length > 0) {
        let orderSelectedData = orderSelected.data[0];
    
        let totalOrder = 0;
    
        if (orderSelectedData) {
          var customer_name = "";
    
          if (orderSelectedData.farm_code !== null) {
            if (orderSelectedData.farm_code.name) {
              customer_name = orderSelectedData.farm_code.name;
            }
          }

          orderSelectedData.customer_name = customer_name;
          
          orderSelectedData.sales_order_items.map(data => {
            if (data.sales_order_item !== null) {
              totalOrder = totalOrder + data.sales_order_item.unit_price * data.sales_order_item.quantity;
            }
          });
    
          orderSelectedData.total_order = totalOrder;
    
          orderSelectedData.statusIconPath = getStatusIconPath(orderSelectedData.status);
        }

        setSelectedOrder(orderSelectedData);
    
        if(orderSelectedData.status == 'draft') {
          setIsEditable(true);
        } else {
          setIsEditable(false);
        }

        setDeliveryAddressOptionId(orderSelectedData.delivery_address_option ?? 0);

        if (orderSelectedData.delivery_address_option === -1) {
          setInputAddressValue(
            `${orderSelectedData.delivery_address_line_1? orderSelectedData.delivery_address_line_1 + ', ' : ''}` +
            `${orderSelectedData.delivery_address_line_2 ? orderSelectedData.delivery_address_line_2 + ', ' : ''}` +
            `${orderSelectedData.delivery_address_city ? orderSelectedData.delivery_address_city + ' ' : ''}` +
            `${orderSelectedData.delivery_address_state ? orderSelectedData.delivery_address_state + ' ' : ''}` +
            `${orderSelectedData.delivery_address_postcode ? orderSelectedData.delivery_address_postcode + ' ' : ''}`
          );
          setDeliveryAddressOptionSelected('');
        } else {
          setInputAddressValue(null);
        }

        changeDeliveryAddressOption(orderSelectedData.farm_code);

        setSelectedCustomer(orderSelectedData.farm_code);
        setAdd(false);
        setTotalOrderItem(totalOrder);
        loadSalesOrderItems();
        setEmail(orderSelectedData.cc_email);
        setDeliveryInstructions(orderSelectedData.delivery_instructions);
        handleDeliveryDateChange(orderSelectedData.due_date ? moment(orderSelectedData.due_date).format() : Date.now());
        setNotes(orderSelectedData.notes);
        setOrderDiscount(orderSelectedData.order_discount);
        setShipping(orderSelectedData.shipping);
      }
    } else {
      setAdd(true);
      setIsEditable(true);
      setDeliveryAddress(null);
      setTotalOrderItem(0);
      setEmail('');
      setDeliveryInstructions(null);
      setNotes('');
      handleDeliveryDateChange(new Date())
      setSelectedCustomer(null);
      setSelectedOrder({statusIconPath: getStatusIconPath('draft'), status: 'draft'});
      setItems(null);
      setOrderDiscount(0);
      setShipping(0);
    }
    countTotal();
    setOrderLoading(false);
  }

  const loadCustomer = async () => {
    setCustomerLoading(true);
    try {
      var customer_filter = { status: 'published' };
      var Customers = null;

      var fields = `id, email, code, name, selectable, access_level, accounting_system, legal_name, 
      delivery_address_line_1, delivery_address_line_2, delivery_city, delivery_state, delivery_post_code, additional_address.*`;

      var customerCache = getCache('customers');
      if (!customerCache) {
        Customers = await directus.getItems('customers', {
          fields: fields,
          sort: 'name',
          filter: customer_filter
        });

        setCache('customers', Customers.data);
      } else {
        Customers = { data: customerCache };
      }

      Customers = Customers.data;
      var customers_data = Customers;
      var fav_customers = [];

      if (localStorage.directus_employee) {
        var directus_employee = JSON.parse(localStorage.directus_employee);
        if (directus_employee.favourites_customers_po) {
          fav_customers = directus_employee.favourites_customers_po;
        }
      }

      customers_data.map((value, index) => {
        if (fav_customers.indexOf(value.id) >= 0) {
          customers_data[index].isFavourite = true;
        } else {
          customers_data[index].isFavourite = false;
        }
      });

      customers_data = Enumerable.from(customers_data)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();

      setCustomerOptions(customers_data);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
    setCustomerLoading(false);
  };

  const loadItems = async () => {
    setItemsLoading(true);
    try {
      var Items = null;
      var itemsCache = getCache('items');
      if (!itemsCache) {
        Items = await directus.getItems('items', {
          fields: '*',
          sort: 'name',
        });

        setCache('items', Items.data);
      } else {
        Items = { data: itemsCache };
      }

      Items = Items.data;
      var items_data = Items;

      items_data = Enumerable.from(items_data)
        .orderBy(item => item.name)
        .toArray();

      setItemsOptions(items_data);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
    setItemsLoading(false);
  };

  const loadSalesOrderItems = () => {
    setLoadingItems(true);
    let itemData = [];

    if (selectedOrderRef.current && selectedOrderRef.current.sales_order_items.length > 0) {
      //eslint-disable-next-line array-callback-return
      selectedOrderRef.current.sales_order_items.map((data, index) => {
        itemData[index] = { ...data.sales_order_item, statusValue: 0, count: index, orders_items_id: data.id };
      });
      setItems(itemData);
      setItemCount(itemData.length);
      var maxSort = linq.from(selectedOrderRef.current.sales_order_items).maxBy(s => s.sales_order_item.sort);
      if (maxSort) {
        setMaxSort(maxSort.sales_order_item.sort);
      }
    } else {
      setItems(null);
      setItemCount(0);
      setSubTotal(0);
      setTotalTax(0);
    }

    addItemsCheck(itemCountRef.current);
    setLoadingItems(false);
  };

  const loadOrderEventType = async () => {
    let company = JSON.parse(window.localStorage.getItem("company"));
    let token = JSON.parse(window.localStorage.getItem("directus_data"));

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.token
      }
    };

    var sales_order_event_type = getCache("sales_order_event_type_options_choices");

    if (!sales_order_event_type) {
      fetch(`${react_app_url}${company}/fields/sales_order_event/type`, requestOptions)
        .then(response => response.json())
        .then(async result => {
          if (result.data) {
            setOrderEventType(result.data.options.choices);
            setCache("sales_order_event_type_options_choices", result.data.options.choices);
          }
        });
    } else {
      setOrderEventType(sales_order_event_type);
    }
  };

  const getConfigurationSettings = async () => {
    var result = await directus.getItems('configuration');
    if (result.data.length > 0) {
      if (result.data[0]) {
        if (result.data[0].show_sales_orders_total_details === true) {
          setShowTotalDetails(true);
        }
      }
    }
  }

  const addItemsCheck = itemCount => {
    let saveItems = [];
    //eslint-disable-next-line array-callback-return
    itemsRef.current && itemsRef.current.map(data => {
      if (data.statusValue !== 3) {
        saveItems = itemsRef.current;
        if (itemCount === 0) {
          if (
            data.quantity === undefined ||
            data.quantity === '' ||
            data.quantity === 0 ||
            data.quantity === '0' ||
            data.quantity < 0 ||
            data.description === undefined ||
            data.description === ''
          ) {
            setItemCheck(false);
          } else {
            setItemCheck(true);
          }
        } else {
          if (
            data.quantity === undefined ||
            data.quantity === '' ||
            data.quantity === 0 ||
            data.quantity === '0' ||
            data.quantity < 0 ||
            data.description === undefined ||
            data.description === ''
          ) {
            setItemCheck(false);
          } else {
            setItemCheck(true);
          }
        }
      }
    });

    //eslint-disable-next-line array-callback-return
    saveItems.map(data => {
      if (
        data.quantity === undefined ||
        data.quantity === '' ||
        data.quantity === 0 ||
        data.quantity === '0' ||
        data.quantity < 0 ||
        data.description === undefined ||
        data.description === ''
      ) {
        setItemCheck(false);
      }
    });
  };

  const countTotal = () => {
    if (itemsRef.current) {
      let subtotal = 0;
      
      //eslint-disable-next-line array-callback-return
      itemsRef.current.map(data => {
        if (data.statusValue !== 3) {
          if (data.unit_price && data.quantity) {
            let item_total = data.unit_price * data.quantity;
            if (data.item_discount) {
              if (data.item_discount !== '0' && data.item_discount !== 0) {
                const item_total_discount = (item_total * data.item_discount) / 100;
                item_total -= item_total_discount;
              }
            }
            subtotal = subtotal + parseFloat(item_total);
          }
        }
      });
      const tax = 10 * subtotal / 100;
      let total = subtotal;

      setSubTotalIncludingTax(total);

      if (total > 0) {
        if (orderDiscountRef.current > 0) {
          total -= parseFloat(orderDiscountRef.current);
        }
  
        if (shippingRef.current > 0) {
          total += parseFloat(shippingRef.current);
        }
      }
  
      setSubTotal(subtotal.toFixed(2));
      setTotalTax(tax.toFixed(2));
      setTotalOrderItem(total.toFixed(2));
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;

    if (name === 'email') {
      setEmail(value);
    } else if (name === 'delivery_instructions') {
      setDeliveryInstructions(value);
    } else if (name === 'notes') {
      setNotes(value);
    } else if (name === 'order_discount') {
      setOrderDiscount(value);
      countTotal();
    } else if (name === 'shipping') {
      setShipping(value);
      countTotal();
    }
  };

  const formatNumber = num => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  const clickCustomerFavorite = (option) => {
    var enable_click = false;
    if (option.selectable) {
      enable_click = true;
    }

    if (option.access_level) {
      if (option.access_level === 'po_creator') {
        if (!POCreatorPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'po_approver') {
        if (!POApproverPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'invoice_admin') {
        if (!InvoiceAdminPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'invoice_admin') {
        if (!AdminPermission()) {
          enable_click = false;
        }
      }
    }

    if (AdminPermission()) {
      enable_click = true;
    }

    if (enable_click) {
      setIsClickFavourite(true);
      let customerOptionsData = customerOptionsRef.current.map((data, index) => {
        if (data.id == option.id) {
          data.isFavourite = !option.isFavourite;
        }
        return data;
      })
      
  
      const updateCustomerOption = Enumerable.from(customerOptionsData)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();
      
      setCustomerOptions(updateCustomerOption);
    }
  };

  const resetCustomer = async e => {
    try {
      if (isClickFavouriteRef.current) {
        var fav_customers_data = Enumerable.from(customerOptionsRef.current)
          .where(w => w.isFavourite === true)
          .select(data => data.id)
          .toArray();

        var fav_customers = [];
        var directus_employee = null;
        if (localStorage.directus_employee) {
          directus_employee = JSON.parse(localStorage.directus_employee);
          if (directus_employee.favourites_customers_po) {
            fav_customers = directus_employee.favourites_customers_po;
          }
        }

        var isUpdateFavourite = false;
        if (fav_customers.length === 0 && fav_customers_data.length === 0) {
          isUpdateFavourite = true;
        } else if (fav_customers.length !== fav_customers_data.length) {
          isUpdateFavourite = true;
        } else {
          isUpdateFavourite = true;
          fav_customers_data.map(value => {
            if (fav_customers.indexOf(value) >= 0) {
              isUpdateFavourite = false;
            }
          });

          if (!isUpdateFavourite) {
            isUpdateFavourite = true;
            fav_customers.map(value => {
              if (fav_customers_data.indexOf(value) >= 0) {
                isUpdateFavourite = false;
              }
            });
          }
        }

        if (isUpdateFavourite) {
          directus_employee.favourites_customers_po = fav_customers_data;
          localStorage.directus_employee = JSON.stringify(directus_employee);
          await directus.updateItem('employees', directus_employee.id, { favourites_customers_po: fav_customers_data });
        }
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
    setIsClickFavourite(false);
  };

  const addItems = itemVar => {
    if (itemVar === null) {
      let data = [];
      data.id = uuidv4();
      data.statusValue = 1;
      data.count = 0;
      setItems([data]);
      setItemCount(data.count);
    } else {
      let data = [];
      data.id = uuidv4();
      data.statusValue = 1;
      data.count = (itemsRef.current.length > 0 ? itemsRef.current[itemsRef.current.length - 1].count : 0) + 1;
      data.sort = maxSort + 1;
      setItems([...itemsRef.current, data]);
      setItemCount(data.count);
    }

    addItemsCheck(itemCountRef.current);
  };

  const deleteItems = itemData => {
    if (itemData.statusValue === 1) {
      setItems(itemsRef.current.filter(data => data.count !== itemData.count));
    } else {
      setItems(itemsRef.current.map(data => (data.count === itemData.count ? { ...data, statusValue: 3 } : data)));
    }
    
    deleteItemsCheck(itemData);
    addItemsCheck(itemCountRef.current);
    countTotal();
  };

  const deleteItemsCheck = itemData => {
    let items = [];
    items = itemsRef.current.filter(data => data.count !== itemData.count);

    /* eslint-disable eqeqeq*/
    if (
      items.quantity == '' ||
      items.quantity === 0 ||
      items.quantity === '0' ||
      items.description == '' ||
      items.quantity == undefined ||
      items.quantity < 0 ||
      items.description == undefined
    ) {
      setItemCheck(false);
    }
    /* eslint-enable eqeqeq*/
  };

  const changeItems = (e, oldData, newData) => {
    var name;
    var value;
    if (e) {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = newData.name;
      value = newData.value;
    }
    
    setItems(
      items.map(dataList =>
        dataList.id === oldData.id
          ? dataList.statusValue === 1
            ? { ...oldData, statusValue: 1, [name]: value }
            : { ...oldData, statusValue: 2, [name]: value }
          : dataList
      )
    );

    countTotal();
    addItemsCheck(itemCountRef.current);
  };

  const handleOpenChangeLog = async () => {
    setOpenChangeLog(true);
    setLogDataLoading(true);
    if(selectedOrderRef.current) {
      var OrderEvent = await directus.getItems('sales_order_event', {
        fields: '*.*',
        filter: {
          sales_order: selectedOrderRef.current.id
        },
        sort: '-created_on'
      });
      setLogData(OrderEvent.data);
    }
    setLogDataLoading(false);
  };
  
  const closeCustomEmail = () => {
    setIsCustomEmail(false);
    setDataCustomEmail({ send_to_email: null, cc_email: null });
  };

  /* eslint-disable array-callback-return*/
  const editSubmit = async (id, type, send_customer = false, custom_send = false) => {
    setLoadingSubmit(true);

    let addItems = [];
    let editItems = [];
    let delItems = [];
    let delItems2 = [];

    var sort = maxSort + 1;
    if (items) {
      items.map((data, index) => {
        if (data.statusValue === 3) {
          delItems = [...delItems, data.orders_items_id];
          delItems2 = [...delItems2, data.id];
        }
        if (data.statusValue === 2) {
          if (data.sort !== null && data.sort !== undefined && data.sort !== '') {
            editItems = [
              ...editItems,
              {
                id: data.id,
                description: data.description,
                quantity: data.quantity,
                unit_price: data.unit_price,
                cc_email: data.cc_email,
                note: data.note,
                sort: data.sort,
                internal_code: data.internal_code,
                item_discount: data.item_discount ?? 0,
                sales_item: data.sales_item.id,
              }
            ];
          } else {
            editItems = [
              ...editItems,
              {
                id: data.id,
                description: data.description,
                quantity: data.quantity,
                unit_price: data.unit_price,
                cc_email: data.cc_email,
                note: data.note,
                internal_code: data.internal_code,
                item_discount: data.item_discount ?? 0,
                sales_item: data.sales_item.id,
              }
            ];
          }
        }

        if (data.statusValue === 1 && data.description) {
          var defaultSort = sort;
          if (data.sort !== null && data.sort !== undefined) {
            defaultSort = data.sort;
          }
          addItems = [
            ...addItems,
            {
              sales_order: { id: id },
              sales_order_item: {
                id: data.id,
                description: data.description,
                quantity: data.quantity,
                unit_price: data.unit_price,
                sort: defaultSort,
                internal_code: data.internal_code,
                item_discount: data.item_discount ?? 0,
                sales_item: data.sales_item.id,
              }
            }
          ];
          sort++;
        }
      });
    }

    if (delItems.length > 0) {
      await directus.deleteItems('sales_orders_items', delItems);
      await directus.deleteItems('sales_order_items', delItems2);
    }

    if (editItems.length > 0) {
      await directus.updateItems('sales_order_items', editItems);
    }

    if (addItems.length > 0) {
      for (let index = 0; index < addItems.length; index++) {
        var addItem = addItems[index];
        delete addItem.sales_order_item.id;

        var orderItemResult = await directus.createItem('sales_order_items', addItem.sales_order_item);
        addItems[index].sales_order_item = { id: orderItemResult.data.id };
      }

      await directus.createItems('sales_orders_items', addItems);
    }

    if (custom_send) {
      await directus.updateItem(
        'sales_orders',
        id,
        {
          delivery_instructions: deliveryInstructions,
          delivery_address_name: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressName : '',
          delivery_address_line_1: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressLine1 : '',
          delivery_address_line_2: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressLine2 : '',
          delivery_address_city: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressCity : '',
          delivery_address_state: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressState : '',
          delivery_address_postcode: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressPostcode : '',
          delivery_address_option: deliveryAddressOptionIdRef.current,
          farm_code: selectedCustomer ? selectedCustomer.id : null,
          due_date: selectedDeliveryDate ? moment(new Date(selectedDeliveryDate)).format('YYYY-MM-DD') : null,
          status: type,
          notes: notes,
          send_to_email: dataCustomEmailRef.current.send_to_email,
          cc_email: dataCustomEmailRef.current.cc_email,
          order_discount: orderDiscountRef.current,
          shipping: shippingRef.current
        },
        { send_customer: send_customer, custom_send: custom_send }
      );
    } else {
      await directus.updateItem(
        'sales_orders',
        id,
        {
          delivery_instructions: deliveryInstructions,
          delivery_address_name: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressName : '',
          delivery_address_line_1: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressLine1 : '',
          delivery_address_line_2: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressLine2 : '',
          delivery_address_city: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressCity : '',
          delivery_address_state: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressState : '',
          delivery_address_postcode: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressPostcode : '',
          delivery_address_option: deliveryAddressOptionIdRef.current,
          cc_email: email,
          order_discount: orderDiscountRef.current,
          shipping: shippingRef.current,
          farm_code: selectedCustomer ? selectedCustomer.id : null,
          notes: notes,
          due_date: selectedDeliveryDate ? moment(new Date(selectedDeliveryDate)).format('YYYY-MM-DD') : null,
          status: type
        },
        { send_customer: send_customer }
      );
    }

    loadOrderSelected();
    EventEmitter.emit('load_all_orders', { loadStatus: false });

    setLoadingSubmit(false);
    var message = type === 'draft' ? 'Order has been saved as a draft' : 'Order has been sent';
    enqueueSnackbar(message, {
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      variant: 'success',
      autoHideDuration: 3200,
      action: CloseSnackbarAction
    });

    if (custom_send) {
      setCustomEmailLoading(false);
      closeCustomEmail();
    }
  };

  const editSave = async id => {
    setLoadingEdit(true);

    let editItems = [];

    //eslint-disable-next-line array-callback-return
    selectedOrder && selectedOrder.sales_order_items.map((data, index) => {
      editItems = [
        ...editItems,
        {
          id: data.sales_order_item.id,
          description: data.sales_order_item.description,
          quantity: data.sales_order_item.quantity,
          unit_price: data.sales_order_item.unit_price,
          cc_email: data.cc_email,
          note: data.note,
          internal_code: data.internal_code,
          item_discount: data.item_discount ?? 0,
          sales_item: data.sales_item && data.sales_item.id,
        }
      ];
    });

    if (editItems.length > 0) {
      await directus.updateItems("sales_order_items", editItems);
    }

    await directus.updateItem('sales_orders', id, {
      cc_email: email,
      farm_code: selectedCustomer ? selectedCustomer.id : null,
      status: "completed",
    });
    loadOrderSelected();
    setLoadingEdit(false);

    var message = "Data updated successfully";
    enqueueSnackbar(message, {
      anchorOrigin: { vertical: "top", horizontal: "right" },
      variant: "success",
      autoHideDuration: 3200,
      action: CloseSnackbarAction
    });
  };

  const checkEnableClick = (option) => {
    var enable_click = false;
    if (option.selectable) {
      enable_click = true;
    }

    if (option.access_level) {
      if (option.access_level === 'po_creator') {
        if (!POCreatorPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'po_approver') {
        if (!POApproverPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'invoice_admin') {
        if (!InvoiceAdminPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'invoice_admin') {
        if (!AdminPermission()) {
          enable_click = false;
        }
      }
    }

    if (AdminPermission()) {
      enable_click = true;
    }

    return enable_click;
  }

  const changeDeliveryAddressOption = newValue => {
    let newDeliveryAddressOption = generateDeliveryOption(newValue);
    const selectedAddressFromOption = newDeliveryAddressOption.filter(address => address.id === deliveryAddressOptionIdRef.current);

    if (selectedAddressFromOption.length > 0) {
      setDeliveryAddress(selectedAddressFromOption[0]);
      formattingDeliveryAddress(selectedAddressFromOption[0]);
    } else {
      setDeliveryAddress('');
    }

    setDeliveryAddressOptions(newDeliveryAddressOption);
  }
  
  const formattingDeliveryAddress = (address) => {
    let address_name = address.address_name;
    let address_line_1 = '';
    let address_line_2 = '';
    let address_city = '';
    let address_state = '';
    let address_postcode = '';

    if (address && address.id !== -1) {
      let delivery_address_line_1 = address.address_line_1 ?? '';
      let delivery_address_line_2 =  address.address_line_2 ?? '';
      let delivery_city = address.city;
      let delivery_state = address.state;
      let delivery_post_code = address.post_code;
  
      if (delivery_address_line_1) {
        address_line_1 += delivery_address_line_1;
      }
  
      if (delivery_address_line_2) {
        address_line_2 += delivery_address_line_2;
      }

      if (delivery_city) {
        address_city += delivery_city
      }
      
      if (delivery_state) {
        address_state += delivery_state
      }
      
      if (delivery_post_code) {
        address_postcode += delivery_post_code
      }
    } else if (address.id === -1) {
      if (selectedOrderRef.current && selectedOrderRef.current.delivery_address_line_1) {
        address_line_1 += selectedOrderRef.current.delivery_address_line_1;
      }

      if (selectedOrderRef.current && selectedOrderRef.current.delivery_address_line_2) {
        address_line_2 += selectedOrderRef.current.delivery_address_line_2;
      }

      if (selectedOrderRef.current && selectedOrderRef.current.delivery_address_city) {
        address_city += selectedOrderRef.current.delivery_address_city;
      }

      if (selectedOrderRef.current && selectedOrderRef.current.delivery_address_state) {
        address_state += selectedOrderRef.current.delivery_address_state;
      }

      if (selectedOrderRef.current && selectedOrderRef.current.delivery_address_postcode) {
        address_postcode += selectedOrderRef.current.delivery_address_postcode;
      }
    }
    
    setSelectedDeliveryAddress({
      AddressName: address_name,
      AddressLine1: address_line_1,
      AddressLine2: address_line_2,
      AddressCity: address_city,
      AddressState: address_state,
      AddressPostcode: address_postcode,
    })
  }

  const onChangeAddress = async (data) => {
    setDeliveryAddressOptionSelected(data);
    if (data) {
      var address = data.label;
      var subpremise = '';
      var street_number = '';
      var route = '';
      var check_address = '';
      var Zip = '';
      var State = '';
      var City = '';

      setInputAddressValue(address);

      const results = await geocodeByAddress(address);

      if (results[0]) {

        /* eslint-disable array-callback-return */
        results[0].address_components.map((value) => {
          if (value.types) {
            value.types.map((types_value) => {
              if (types_value === "subpremise") {
                subpremise = "U" + value.long_name + '/';
                check_address = "U" + value.long_name + '/';
              }

              if (types_value === "street_number") {
                street_number = value.long_name + ' ';
                check_address = check_address + ' ' + value.long_name;
              }

              if (types_value === "route") {
                route = value.long_name + '';
                check_address = check_address + ' ' + value.long_name;
              }

              if (types_value === "postal_code") {
                Zip = value.long_name;
              }

              if (types_value === "administrative_area_level_1") {
                State = value.short_name;
              }

              if (types_value === "locality") {
                City = value.long_name;
              }

            })
          }
        })
        /* eslint-enable array-callback-return */
      }

      if (check_address.length <= 30) {
        setSelectedDeliveryAddress({
          ...selectedDeliveryAddressRef.current,
          AddressLine1: subpremise + '' + street_number + '' + route,
          AddressLine2: ''
        })
      } else {
        setSelectedDeliveryAddress({
          ...selectedDeliveryAddressRef.current,
          AddressLine1: subpremise,
          AddressLine2: street_number + ' ' + route
        })
      }

      setSelectedDeliveryAddress({
        ...selectedDeliveryAddressRef.current,
        AddressName: 'Custom',
        AddressCity: City,
        AddressState: State,
        AddressPostcode: Zip,
      })
    } else {
      setInputAddressValue('');
    }
  }

  const itemTotalCount = (qty, unit_price, item_discount) => {
    let total = unit_price;
    
    if (qty && qty > 0) {
      total = qty * unit_price;
      if (item_discount) {
        if (item_discount !== '0' && item_discount !== 0) {
          const total_item_discount = (total * item_discount) / 100;
          total -= total_item_discount;
        }
      }
    }

    return isNaN(total) ? '$0.00' : '$' + formatNumber((Math.round(total * 100) / 100).toFixed(2));
  }

  const handleChangeSalesItem = (item, oldData) => {
    setItems(
      itemsRef.current.map(dataList =>
        dataList.id === oldData.id
          ? dataList.statusValue === 1
            ? { ...oldData, statusValue: 1, internal_code: item.item_number, description: item.description, unit_price: item.price, sales_item: item }
            : { ...oldData, statusValue: 2, internal_code: item.item_number, description: item.description, unit_price: item.price, sales_item: item }
          : dataList
      )
    );
    addItemsCheck(itemCountRef.current);
    countTotal();
  }

  var disabledCheck = selectedCustomer === null || itemCheck === false;
  let delivery_address_check = deliveryAddressOptionIdRef.current === -1 ? inputAddressValueRef.current : selectedDeliveryAddressRef.current.AddressLine1;

  return (
    <div>
      {orderLoading ?
        <>
          <Grid container spacing={0} justifyContent="space-around">
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Skeleton animation="wave" variant="rounded" width="40%" height={50} style={{ borderRadius: '10px', marginBottom: '10px' }} />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.flexEnd}>
              <Skeleton animation="wave" variant="rounded" width="50%" height={50} style={{ borderRadius: '10px', marginBottom: '10px' }} />
            </Grid>
          </Grid>
          <Grid container spacing={0} justifyContent="space-around" style={{ marginTop: '20px' }}>
            <Grid item xs={7} sm={7} md={7} lg={7} style={{ paddingRight: '16px'}}>
              <Skeleton animation="wave" variant="rounded" width="100%" height={44} style={{ borderRadius: '10px', marginBottom: '10px' }} />
            </Grid>
            <Grid item xs={5} sm={5} md={5} lg={5}>
              <Skeleton animation="wave" variant="rounded" width="100%" height={44} style={{ borderRadius: '10px', marginBottom: '10px' }} />
            </Grid>
          </Grid>
          <Grid container spacing={0} justifyContent="space-around" style={{ marginTop: '20px' }}>
            <Grid item xs={7} sm={7} md={7} lg={7} style={{ paddingRight: '16px'}}>
              <Skeleton animation="wave" variant="rounded" width="100%" height={88} style={{ borderRadius: '10px', marginBottom: '10px' }} />
            </Grid>
            <Grid item xs={5} sm={5} md={5} lg={5}>
              <Skeleton animation="wave" variant="rounded" width="100%" height={88} style={{ borderRadius: '10px', marginBottom: '10px' }} />
            </Grid>
          </Grid>
          <Grid container spacing={0} justifyContent="space-around" style={{ marginTop: '20px' }}>
            <Grid item xs={7} sm={7} md={7} lg={7} style={{ paddingRight: '16px'}}>
              <Skeleton animation="wave" variant="rounded" width="100%" height={44} style={{ borderRadius: '10px', marginBottom: '10px' }} />
            </Grid>
            <Grid item xs={5} sm={5} md={5} lg={5}>
              <Skeleton animation="wave" variant="rounded" width="100%" height={44} style={{ borderRadius: '10px', marginBottom: '10px' }} />
            </Grid>
          </Grid>
          <Skeleton animation="wave" variant="rounded" width="10%" height={44} style={{ borderRadius: '10px', marginTop: '20px' }} />
          <Grid container spacing={0} justifyContent="space-around" style={{ marginTop: '20px' }}>
            <Grid item xs={7} sm={7} md={7} lg={7} style={{ paddingRight: '16px'}}>
              <Skeleton animation="wave" variant="rounded" width="100%" height={148} style={{ borderRadius: '10px', marginBottom: '10px' }} />
            </Grid>
            <Grid item xs={5} sm={5} md={5} lg={5}>
              <Skeleton animation="wave" variant="rounded" width="100%" height={148} style={{ borderRadius: '10px', marginBottom: '10px' }} />
            </Grid>
          </Grid>
          <Grid container spacing={0} justifyContent="space-around" style={{ marginTop: '40px' }}>
            <Grid item xs={3} sm={3} md={3} lg={3} style={{ paddingRight: '16px'}}>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}  className={classes.flexEnd} style={{ paddingRight: '16px'}}>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}  className={classes.flexEnd} style={{ paddingRight: '16px'}}>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}  className={classes.flexEnd}>
              <Skeleton animation="wave" variant="rounded" width="100%" height={44} style={{ borderRadius: '10px', marginBottom: '10px' }} />
            </Grid>
          </Grid>
        </>
      :
        add ?
          <AddOrder />
        :
          <>
            <Grid container spacing={0} justifyContent="space-around">
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Typography noWrap component="div" style={{ paddingRight: '20px', fontWeight: '590', fontSize: '20px' }}>
                    <OverflowTip>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '5px' }}>
                        Sales Order
                        <InsertDriveFileIcon
                          style={{
                            color: 'white',
                            backgroundColor: '#516573',
                            padding: '3px',
                            borderRadius: '50%',
                            fontSize: '20px',
                            marginRight: '5px'
                          }}
                        />
                      </div>
                    </OverflowTip>
                </Typography>
                <Typography
                  noWrap
                  component="div"
                  className={classes.typographyStyle2}
                  style={{ fontWeight: '400', fontSize: '17px', color: 'rgba(60, 60, 67, 0.6)' }}
                >
                  <OverflowTip>
                    {selectedOrderRef.current
                      ? add || selectedOrderRef.current.status === 'draft'
                        ? ''
                        : `S${selectedOrderRef.current.order_number}`
                      : ''}
                  </OverflowTip>
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} className={classes.flexEnd}>  
                <OverflowTip>
                  <div style={{ display: 'flex', columnGap: '10px' }}>
                    {add ? '' :
                      <IconButton onClick={() => handleOpenChangeLog()} aria-label="delete" size="small" style={{  width: '47px', height: '40px', background: 'white', alignItems: 'center', justifyContent: 'center', borderRadius: '12px' }}>
                        <img src='/static/images/icons/history.svg' />
                      </IconButton>
                    }
                    <IconButton aria-label="delete" size="small" style={{  height: '40px', background: 'white', alignItems: 'center', justifyContent: 'center', borderRadius: '12px', paddingRight: '20px', paddingLeft: '20px' }}>
                      <img src={selectedOrderRef.current ? selectedOrderRef.current.statusIconPath : ''} />
                      <Typography
                        noWrap
                        component="div"
                        className={classes.typographyStyle2}
                        style={{ paddingLeft: '10px', color: '#374355', lineHeight: '22px', textTransform: 'capitalize' }}
                      >
                        <OverflowTip>{selectedOrderRef.current ? selectedOrderRef.current.status : ''}</OverflowTip>
                      </Typography>
                    </IconButton>
                  </div>
                </OverflowTip>
              </Grid>
            </Grid>
            {isEditable || add ?
              <>
                <Grid container spacing={0} justifyContent="space-around" style={{ marginTop: '20px' }}>
                  <Grid item xs={7} sm={7} md={7} lg={7} style={{ paddingRight: '16px'}}>
                    <>
                      {/* -------------------- CUSTOMER DETAILS -------------------- */}
                      <div className='customer'>
                        <Typography
                          noWrap
                          component="div"
                          className={classes.typographyStyle}
                          style={{ paddingLeft: '16px' }}
                        >
                          <OverflowTip>Customer Details</OverflowTip>
                        </Typography>
                        <div style={{ backgroundColor: 'white', height: '44px', borderRadius: '10px', padding: '5px'}}>
                          <Grid container spacing={0}>
                            <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                              <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Customer Name</Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8}>    
                              <Autocomplete
                                id="customer-options"
                                options={customerOptionsRef.current}
                                className={classes.autocompleteEditable}
                                filterOptions={filterOptions}
                                disableClearable
                                popupIcon={<img src='/static/images/icons/updown.svg' />}
                                getOptionLabel={(option) => option.name ?? ''}
                                isOptionEqualToValue={(option, value) => option.id == value.id }
                                value={selectedCustomer ?? ''}
                                onClose={() => resetCustomer()}
                                sx={{
                                  '& .MuiAutocomplete-input': { 
                                    textAlign: 'right',
                                    color: 'rgba(60, 60, 67, 0.6)',
                                  },
                                }}
                                onChange={(event, newValue) => {
                                  const enable_click = checkEnableClick(newValue);

                                  let currenttlySelectedCustomer = true;
                                  if (selectedCustomerRef.current) {
                                    currenttlySelectedCustomer = newValue.id !== selectedCustomerRef.current.id
                                  }

                                  if (enable_click && currenttlySelectedCustomer) {
                                    setSelectedCustomer(newValue);
                                    setDeliveryAddressOptionId(0);
                                    setSelectedDeliveryAddress({
                                      AddressLine1: '',
                                      AddressLine2: '',
                                      AddressName: '',
                                      AddressCity: '',
                                      AddressState: '',
                                      AddressPostcode: '',
                                    });
                                    changeDeliveryAddressOption(newValue);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder='Select Customer'
                                  />
                                )}
                                renderOption={(props, option) => {
                                  const enable_click = checkEnableClick(option)

                                  let isSelected = false;
                                  if (selectedCustomer) {
                                    isSelected = option.id === selectedCustomer.id;
                                  }

                                  return (
                                    <MenuItem
                                      {...props}
                                      selected={isSelected}
                                      className={isSelected ? classes.selectedMenuItem : classes.menuItem}
                                      disabled={!enable_click}
                                    >
                                      <Box display="flex" justifyContent="space-between" width="100%"> 
                                        <span style={{ overflowX: 'hidden', textOverflow: 'ellipsis', paddingRight: '5px' }}>{option.name}</span>
                                        <div onClick={e => clickCustomerFavorite(option)}>
                                          {option.isFavourite ? (
                                            <StarRoundedIcon fontSize="small" />
                                          ) : (
                                            <StarBorderRoundedIcon fontSize="small" />
                                          )}
                                        </div>
                                      </Box>
                                    </MenuItem>
                                  )
                                }}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </>
                  </Grid>
                  <Grid item xs={5} sm={5} md={5} lg={5}>
                    <>
                      {/* -------------------- DATE -------------------- */}
                      <div className='date'>
                        <Typography
                          noWrap
                          component="div"
                          className={classes.typographyStyle}
                          style={{ paddingLeft: '16px' }}
                        >
                          <OverflowTip>Date</OverflowTip>
                        </Typography>
                        <div style={{ backgroundColor: 'white', height: '44px', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
                          <Typography
                            noWrap
                            component="div"
                            className={classes.typographyStyle2}
                            style={{ paddingLeft: '16px' }}
                          >
                            <OverflowTip>{moment(selectedOrderRef.current ? selectedOrderRef.current.order_date : Date.now()).format('DD MMMM YYYY')}</OverflowTip>
                          </Typography>
                        </div>
                      </div>
                    </>
                  </Grid>
                </Grid>

                {/* -------------------- TABLE HEADER -------------------- */}
                <Grid container spacing={0} justifyContent="space-around" style={{ paddingLeft: '5px', marginTop: '20px', width: 'calc(100% - 29px)' }}>
                  <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridPaddingRight}>
                    <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px'}}>
                      <OverflowTip>Qty</OverflowTip>
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                    <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px'}}>
                      <OverflowTip>Code</OverflowTip>
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridPaddingRight}>
                    <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px'}}>
                      <OverflowTip>Description</OverflowTip>
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                    <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px'}}>
                      <OverflowTip>Unit Price</OverflowTip>
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                    <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px'}}>
                      <OverflowTip>Item Discount</OverflowTip>
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                    <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px', paddingRight: '16px', textAlign: 'right'}}>
                      <OverflowTip>Total Price</OverflowTip>
                    </Typography>
                  </Grid>
                </Grid>

                {/* -------------------- TABLE BODY -------------------- */}
                {itemsRef.current ?
                  itemsRef.current?
                    itemsRef.current.map((dataItem, index) => {
                      const isLastItem = index === itemsRef.current.length - 1;

                      if (dataItem.statusValue !== 3) {
                        return (
                          <Grid key={index} container spacing={0} justifyContent="space-around" style={{ marginBottom: isLastItem ? '0px' : '5px' }}>
                            <Grid item style={{ width: 'calc(100% - 29px)', background: 'white', paddingLeft: '5px', borderRadius: '10px' }}>
                              <Grid container spacing={0} justifyContent="space-around" style={{ height: '44px', display: 'flex', alignItems: 'center' }} >
                                <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridPaddingRight}>
                                  <TextField
                                    id={`order_item_quantity_${index}`}
                                    variant='outlined'
                                    className={
                                      dataItem.quantity && dataItem.quantity !== '0' && dataItem.quantity !== '' && dataItem.quantity > 0
                                        ? classes.textfield3
                                        : classes.textfield3Required
                                    }
                                    fullWidth
                                    name="quantity"
                                    type='number'
                                    value={dataItem.quantity ? dataItem.quantity : ''}
                                    onChange={event => changeItems(event, dataItem)}
                                  />
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                                  {itemsLoading ?
                                    <div style={{ margin: '18px 11px' }}>
                                      <LinearProgress />
                                    </div>
                                  :
                                    <Autocomplete
                                      id={`order_item_code_${index}`}
                                      options={itemsOptionsRef.current}
                                      className={classes.autocompleteEditable}
                                      filterOptions={filterOptionsItemNumber}
                                      disableClearable
                                      classes={{
                                        paper: classes.paperstyle
                                      }}
                                      popupIcon={<img src='/static/images/icons/updown.svg' />}
                                      getOptionLabel={(option) => option.item_number ?? ''}
                                      isOptionEqualToValue={(option, value) => option.id == value.id }
                                      value={dataItem.sales_item ?? ''}
                                      sx={{
                                        '& .MuiAutocomplete-input': { 
                                          textAlign: 'left',
                                          color: 'black',
                                        },
                                      }}
                                      onChange={(event, newValue) => {
                                        handleChangeSalesItem(newValue, dataItem);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                        />
                                      )}
                                      renderOption={(props, option) => {
                                        let isSelected = false;
                                        if (dataItem.sales_item) {
                                          isSelected = option.id === dataItem.sales_item.id;
                                        }

                                        return (
                                          <MenuItem
                                            {...props}
                                            selected={isSelected}
                                            className={isSelected ? classes.selectedMenuItem : classes.menuItem}
                                          >
                                            <Box display="flex" justifyContent="space-between" width="100%">
                                              <Grid container spacing={0} justifyContent="space-around">
                                                <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridStyle}>
                                                  {option.item_number}
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridStyle}>
                                                  {option.description}
                                                </Grid>
                                                <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridStyle}>
                                                  {isNaN(option.price) ? 0 : '$' + formatNumber((Math.round(option.price * 100) / 100).toFixed(2))}
                                                </Grid>
                                              </Grid>
                                            </Box>
                                          </MenuItem>
                                        )
                                      }}
                                    />
                                  }
                                </Grid>
                                <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridPaddingRight}>
                                  {itemsLoading ?
                                      <div style={{ margin: '18px 11px' }}>
                                        <LinearProgress />
                                      </div>
                                    :
                                      <Autocomplete
                                        id={`order_item_description_${index}`}
                                        options={itemsOptionsRef.current}
                                        className={classes.autocompleteEditable}
                                        filterOptions={filterOptionsDescription}
                                        disableClearable
                                        classes={{
                                          paper: classes.paperstyle
                                        }}
                                        popupIcon={<img src='/static/images/icons/updown.svg' />}
                                        getOptionLabel={(option) => option.description ?? ''}
                                        isOptionEqualToValue={(option, value) => option.id == value.id }
                                        value={dataItem.sales_item ?? ''}
                                        sx={{
                                          '& .MuiAutocomplete-input': { 
                                            textAlign: 'left',
                                            color: 'black',
                                          },
                                        }}
                                        onChange={(event, newValue) => {
                                          handleChangeSalesItem(newValue, dataItem);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                          />
                                        )}
                                        renderOption={(props, option) => {
                                          let isSelected = false;
                                          if (dataItem.sales_item) {
                                            isSelected = option.id === dataItem.sales_item.id;
                                          }

                                          return (
                                            <MenuItem
                                              {...props}
                                              selected={isSelected}
                                              className={isSelected ? classes.selectedMenuItem : classes.menuItem}
                                            >
                                              <Box display="flex" justifyContent="space-between" width="100%">
                                                <Grid container spacing={0} justifyContent="space-around">
                                                  <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridStyle}>
                                                    {option.item_number}
                                                  </Grid>
                                                  <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridStyle}>
                                                    {option.description}
                                                  </Grid>
                                                  <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridStyle}>
                                                    {isNaN(option.price) ? 0 : '$' + formatNumber((Math.round(option.price * 100) / 100).toFixed(2))}
                                                  </Grid>
                                                </Grid>
                                              </Box>
                                            </MenuItem>
                                          )
                                        }}
                                      />
                                  }
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                                  <TextField
                                    id={`order_item_unit_price_${index}`}
                                    variant='outlined'
                                    className={classes.textfield3}
                                    fullWidth
                                    name="unit_price"
                                    value={dataItem.unit_price ? parseFloat(dataItem.unit_price).toFixed(2) : ''}
                                    readOnly
                                    InputProps={{
                                      inputComponent: NumberFormatCustom,
                                      readOnly: true
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                                  <TextField
                                    id={`order_item_discount_${index}`}
                                    variant='outlined'
                                    className={classes.textfield3}
                                    fullWidth
                                    name="item_discount"
                                    value={dataItem.item_discount ? dataItem.item_discount : 0}
                                    onChange={event => changeItems(event, dataItem)}
                                    InputProps={{
                                      inputComponent: NumberFormatCustomPercent
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                                  <TextField
                                    id={`order_item_total_price_${index}`}
                                    variant='outlined'
                                    className={classes.textfield4}
                                    fullWidth
                                    name="total_price"
                                    value={itemTotalCount(dataItem.quantity, dataItem.unit_price, dataItem.item_discount)}
                                    readOnly
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item style={{ width: '29px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'end' }}>
                              <IconButton onClick={() => deleteItems(dataItem)} aria-label="delete" size="small" style={{ width: '22px', height: '22px'}} >
                                <img src='/static/images/icons/remove.svg' />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )
                      }
                    })
                  : ''
                : ''}

                {/* -------------------- ADD ITEM -------------------- */}
                <div style={{ display: 'flex', paddingLeft: '6px', marginTop: '15px' }}>
                  <AddItemButton
                    variant="outlined"
                    onClick={() => addItems(items ? items : null)}
                    startIcon={<img src='/static/images/icons/add-item.svg' />}
                  >
                    Add Item
                  </AddItemButton>
                </div>
                <Grid container spacing={0} justifyContent='space-around' style={{ marginTop: '15px' }}>
                  {/* -------------------- DELIVERY -------------------- */}
                  <Grid item xs={7} sm={7} md={7} lg={7}>
                    <Typography
                      noWrap
                      component="div"
                      className={classes.typographyStyle}
                      style={{ marginTop: '17px', paddingLeft: '16px' }}
                    >
                      <OverflowTip>Delivery</OverflowTip>
                    </Typography>
                    <div style={{ backgroundColor: 'white', borderRadius: '10px', marginRight: '16px', padding: '5px 0px 5px 5px' }}>
                      <Grid container spacing={0} style={{ paddingBottom: '5px', paddingRight: '5px' }}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Delivery by</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8}>
                          <ThemeProvider theme={theme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                className={classes.datepickerEditable}
                                inputVariant='outlined'
                                id="date_picker"
                                inputProps={{
                                  readOnly: true,
                                }}
                                format="dd MMMM yyyy"
                                placeholder="dd/MM/yyyy"
                                value={moment(selectedDeliveryDate).format()}
                                onChange={date => handleDeliveryDateChange(date)}
                                keyboardIcon={<img src='/static/images/icons/calendar.svg' />}
                              />
                            </MuiPickersUtilsProvider>
                          </ThemeProvider>
                        </Grid>
                      </Grid>
                      <div style={{ backgroundColor: 'white' }}>
                        <Divider style={{ marginLeft: '11px' }} />
                      </div>
                      <Grid container spacing={0} style={{ padding: '5px 5px 5px 0px' }}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Delivery instructions</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8}>    
                          <TextField
                            variant="outlined"
                            className={classes.textfield2Editable}
                            value={deliveryInstructions ?? ''}
                            placeholder='Add delivery notes'
                            name="delivery_instructions"
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                      <div style={{ backgroundColor: 'white' }}>
                        <Divider style={{ marginLeft: '11px' }} />
                      </div>
                      <Grid container spacing={0} style={{ padding: '5px 5px 0px 0px' }}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Delivery address</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8}>
                          <Autocomplete
                            id="delivery-address-options"
                            options={deliveryAddressOptions}
                            className={classes.autocompleteEditable2}
                            disableClearable
                            popupIcon={<img src='/static/images/icons/updown.svg' />}
                            getOptionLabel={(option) => option.lable || ''} 
                            isOptionEqualToValue={(option, value) => option.id == value.id }
                            value={deliveryAddress ?? ''}
                            sx={{
                              height: '49px !important',
                              '& .MuiAutocomplete-input': { 
                                textAlign: 'right',
                                color: 'rgba(60, 60, 67, 0.6)',
                              },
                              '& .MuiOutlinedInput-root': {
                                height: '49px !important',
                              }
                            }}
                            onChange={(event, newValue) => {
                              setDeliveryAddress(newValue);
                              formattingDeliveryAddress(newValue);
                              setDeliveryAddressOptionId(newValue.id);
                              setDeliveryAddressOptionSelected('');
                              setInputAddressValue('');
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                multiline
                              />
                            )}
                            renderOption={(props, option) => {
                              let isSelected = false;
                              if (deliveryAddress) {
                                isSelected = option.id === deliveryAddress.id;
                              }

                              return (
                                <MenuItem
                                  {...props}
                                  selected={isSelected}
                                  className={isSelected ? classes.selectedMenuItem : classes.menuItem}
                                >
                                  <Box display="flex" justifyContent="space-between" width="100%"> 
                                    <span style={{ wordWrap: 'break-word', overflowWrap: 'break-word', textOverflow: 'ellipsis', whiteSpace: 'normal', paddingRight: '5px' }}>
                                      {option.lable}
                                    </span>
                                  </Box>
                                </MenuItem>
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                      {deliveryAddressOptionIdRef.current === -1 ?
                        <Grid item xs={12} sm={12} md={12} lg={12}  style={{  paddingRight: '5px' }}>
                          <GooglePlacesAutocomplete
                            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                            autocompletionRequest={{
                              componentRestrictions: { country: "au" },
                              types: ['address'],
                              fields: ["address_components", "geometry", "icon", "name"]
                            }}
                            selectProps={{
                              inputValue: inputAddressValueRef.current,
                              className: `select-address-autocomplete address`,
                              placeholder: 'Search address...',
                              blurInputOnSelect: true,
                              onInputChange: (value, { action }) => {
                                if (action === "input-change") {
                                  setInputAddressValue(value)
                                }
                              },
                              onChange: (data) => {
                                onChangeAddress(data);
                              },
                              styles: {
                                option: (provided, { isFocused, isSelected }) => ({
                                  ...provided,
                                  background: isSelected ? "#d5f2be !important" : isFocused ? "#d5f2be !important" : "white"
                                })
                              },
                              value: deliveryAddressOptionSelected,
                              controlShouldRenderValue: true,
                              isClearable: true,
                              filterOption: filterOption,
                            }}
                          />
                        </Grid>
                      : ''}
                    </div>

                    {/* Notes */}
                    <Typography
                      noWrap
                      component="div"
                      className={classes.typographyStyle}
                      style={{ marginTop: '17px', paddingLeft: '16px' }}
                    >
                      <OverflowTip>Notes</OverflowTip>
                    </Typography>
                    <div style={{ backgroundColor: 'white', borderRadius: '10px', marginRight: '16px', padding: '5px' }}>
                      <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>   
                          <TextField
                            variant="outlined"
                            className={`${classes.textfield2Editable} ${classes.leftAlignTextfield}`}
                            value={notes ?? ''}
                            placeholder='Insert note...'
                            name="notes"
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  {/* --------------------- TOTALS --------------------- */}
                  <Grid item xs={5} sm={5} md={5} lg={5} >
                    <Typography
                      noWrap
                      component="div"
                      className={classes.typographyStyle}
                      style={{ marginTop: '17px', paddingLeft: '16px' }}
                    >
                      <OverflowTip>Totals</OverflowTip>
                    </Typography>
                    <div style={{ backgroundColor: 'white', borderRadius: '10px' }}>
                      {showTotalDetails ?
                        <>
                          <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                            <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                              <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Subtotal</Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                              <Typography className={classes.typographyStyle2} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                                <OverflowTip>
                                  {isNaN(subTotalRef.current) ? 0 : '$' + formatNumber((Math.round(subTotalRef.current * 100) / 100).toFixed(2))}
                                </OverflowTip>
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                            <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                              <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Tax (10% GST)</Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                              <Typography className={classes.typographyStyle2} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                                <OverflowTip>
                                  {isNaN(totalTaxRef.current) ? 0 : '$' + formatNumber((Math.round(totalTaxRef.current * 100) / 100).toFixed(2))}
                                </OverflowTip>
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider style={{ marginLeft: '16px' }} />
                          <Grid container spacing={0} justifyContent="space-around" className={showTotalDetails ? classes.gridContainer2 : classes.gridContainer} style={{ paddingRight: '16px' }}>
                            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem} style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', justifyContent: 'center' }}>
                              <Typography
                                noWrap
                                component="div"
                                className={classes.typographyStyle4}
                                style={{ lineHeight: '22px' }}
                              >
                                <OverflowTip>Subtotal</OverflowTip>
                              </Typography>
                              <Typography
                                noWrap
                                component="div"
                                className={classes.typographyStyle2}
                                style={{ color: 'rgba(60, 60, 67, 0.6)' }}
                              >
                                <OverflowTip>Including tax</OverflowTip>
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                              <Typography
                                noWrap
                                component="div"
                                className={classes.typographyStyle4}
                                style={{ color: 'rgba(60, 60, 67, 0.6)' }}
                              >
                                <OverflowTip>
                                  {isNaN(subTotalIncludingTaxRef.current) ? 0 : '$' + formatNumber((Math.round(subTotalIncludingTaxRef.current * 100) / 100).toFixed(2))}
                                </OverflowTip>
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider style={{ marginLeft: '16px' }} />
                        </>
                      :
                        <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                          <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                            <Typography className={classes.typographyStyle4} style={{ color: 'black' }}>Subtotal</Typography>
                          </Grid>
                          <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                            <Typography className={classes.typographyStyle4} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                              <OverflowTip>
                                {isNaN(subTotalRef.current) ? 0 : '$' + formatNumber((Math.round(subTotalRef.current * 100) / 100).toFixed(2))}
                              </OverflowTip>
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                      <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '5px', height: '44px' }}>
                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                          <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Order Discount</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                          <TextField
                            variant="outlined"
                            className={classes.textfield2Editable}
                            value={orderDiscountRef.current ? parseFloat(orderDiscountRef.current).toFixed(2) : ''}
                            name="order_discount"
                            onChange={handleChange}
                            InputProps={{
                              inputComponent: NumberFormatCustom
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '5px', height: '44px' }}>
                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                          <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Shipping</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                          <TextField
                            variant="outlined"
                            className={classes.textfield2Editable}
                            value={shippingRef.current ? parseFloat(shippingRef.current).toFixed(2) : ''}
                            name="shipping"
                            onChange={handleChange}
                            InputProps={{
                              inputComponent: NumberFormatCustom
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Divider style={{ marginLeft: '16px' }} />
                      <Grid container spacing={0} justifyContent="space-around" className={classes.gridContainer2} style={{ paddingRight: '5px'}}>
                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                          <Typography
                            noWrap
                            component="div"
                            className={classes.typographyStyle4}
                            style={{ lineHeight: '22px' }}
                            >
                            <OverflowTip>Total</OverflowTip>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                          <Typography
                            noWrap
                            component="div"
                            className={classes.typographyStyle4}
                            style={{ backgroundColor: 'rgba(118, 118, 128, 0.12)', padding: '6px 11px', borderRadius: '6px' }}
                          >
                            <OverflowTip>
                              {isNaN(totalOrderItemRef.current) ? 0 : '$' + formatNumber((Math.round(totalOrderItemRef.current * 100) / 100).toFixed(2))}
                            </OverflowTip>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </>
            :
              <>
                <Grid container spacing={0} justifyContent="space-around" style={{ marginTop: '20px' }}>
                  <Grid item xs={7} sm={7} md={7} lg={7} style={{ paddingRight: '16px'}}>
                    <>
                      {/* -------------------- CUSTOMER DETAILS -------------------- */}
                      <div className='customer'>
                        <Typography
                          noWrap
                          component="div"
                          className={classes.typographyStyle}
                          style={{ paddingLeft: '16px' }}
                        >
                          <OverflowTip>Customer Details</OverflowTip>
                        </Typography>
                        <div style={{ backgroundColor: 'white', height: '44px', borderRadius: '10px'}}>
                          <Autocomplete
                            id="customer-options"
                            popupIcon={''}
                            options={customerOptionsRef.current}
                            className={classes.autocomplete}
                            disabled
                            getOptionLabel={(option) => option.name ?? ''}
                            value={selectedCustomer ?? ''}
                            sx={{
                              '& .MuiAutocomplete-input': { 
                                color: 'black'
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                              />
                            )}
                          />
                        </div>
                      </div>
                    </>
                  </Grid>
                  <Grid item xs={5} sm={5} md={5} lg={5}>
                    <>
                      {/* -------------------- DATE -------------------- */}
                      <div className='date'>
                        <Typography
                          noWrap
                          component="div"
                          className={classes.typographyStyle}
                          style={{ paddingLeft: '16px' }}
                        >
                          <OverflowTip>Date</OverflowTip>
                        </Typography>
                        <div style={{ backgroundColor: 'white', height: '44px', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
                          <Typography
                            noWrap
                            component="div"
                            className={classes.typographyStyle2}
                            style={{ paddingLeft: '16px' }}
                          >
                            <OverflowTip>{moment(selectedOrderRef.current ? selectedOrderRef.current.order_date : Date.now()).format('DD MMMM YYYY')}</OverflowTip>
                          </Typography>
                        </div>
                      </div>
                    </>
                  </Grid>
                </Grid>
                {/* -------------------- TABLE HEADER -------------------- */}
                <Grid container spacing={0} justifyContent="space-around" style={{ padding: '0px 16px', marginTop: '20px'}}>
                  <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridPaddingRight}>
                    <Typography noWrap component="div" className={classes.typographyStyle}>
                      <OverflowTip>Qty</OverflowTip>
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                    <Typography noWrap component="div" className={classes.typographyStyle}>
                      <OverflowTip>Code</OverflowTip>
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridPaddingRight}>
                    <Typography noWrap component="div" className={classes.typographyStyle}>
                      <OverflowTip>Description</OverflowTip>
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                    <Typography noWrap component="div" className={classes.typographyStyle}>
                      <OverflowTip>Unit Price</OverflowTip>
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                    <Typography noWrap component="div" className={classes.typographyStyle}>
                      <OverflowTip>Item Discount</OverflowTip>
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Typography noWrap component="div" className={classes.typographyStyle} style={{ textAlign: 'right' }}>
                      <OverflowTip>Total Price</OverflowTip>
                    </Typography>
                  </Grid>
                </Grid>

                {/* -------------------- TABLE BODY -------------------- */}
                {itemsRef.current ?
                  itemsRef.current?
                    itemsRef.current.map((dataItem, index) => {
                      const isLastItem = index === itemsRef.current.length - 1;

                      return (
                        <OrderItemsNotDraftView
                          key={index}
                          dataItem={dataItem}
                          index={index}
                          isLastItem={isLastItem}
                          itemTotalCount={itemTotalCount}
                          formatNumber={formatNumber}
                        />
                      )
                    })
                  : ''
                : ''}
                <Grid container spacing={0} justifyContent='space-around' style={{ marginTop: '15px' }}>
                  {/* -------------------- DELIVERY -------------------- */}
                  <Grid item xs={7} sm={7} md={7} lg={7}>
                    <Typography
                      noWrap
                      component="div"
                      className={classes.typographyStyle}
                      style={{ marginTop: '17px', paddingLeft: '16px' }}
                    >
                      <OverflowTip>Delivery</OverflowTip>
                    </Typography>
                    <div style={{ backgroundColor: 'white', borderRadius: '10px', marginRight: '16px', paddingLeft: '16px' }}>
                      <Grid container spacing={0}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Delivery by</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8}>    
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className={classes.datepicker}
                              inputVariant='outlined'
                              id="date_picker"
                              disabled
                              format="dd MMMM yyyy"
                              placeholder="dd/MM/yyyy"
                              value={moment(selectedDeliveryDate).format()}
                              keyboardIcon={<img src='/static/images/icons/calendar.svg' />}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>
                      <div style={{ backgroundColor: 'white' }}>
                        <Divider />
                      </div>
                      <Grid container spacing={0}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Delivery instructions</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8}>    
                          <TextField
                            variant="outlined"
                            className={classes.textfield}
                            value={deliveryInstructions ?? ''}
                            disabled
                            style={{ paddingRight: '16px' }}
                            name="delivery_instructions"
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                      <div style={{ backgroundColor: 'white' }}>
                        <Divider />
                      </div>
                      <Grid container spacing={0} style={{ minHeight: '60px' }}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', justifyContent: 'center' }}>
                          <OverflowTip>
                            <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Delivery address</Typography>
                          </OverflowTip>
                          <OverflowTip>
                            <Typography className={classes.typographyStyle2} style={{ color: 'rgba(60, 60, 67, 0.6)' }}>
                            {selectedDeliveryAddressRef.current.AddressName}
                            </Typography>
                          </OverflowTip>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} style={{ display: 'flex', alignItems: 'center' }}>
                          <div className={classes.deliveryaddressstyle} style={{  display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div>
                              {
                                selectedOrderRef.current
                                  ? `${selectedOrderRef.current.delivery_address_line_1 ?? ''}${selectedOrderRef.current.delivery_address_line_2 ? ', ' + selectedOrderRef.current.delivery_address_line_2 + ',' : ''}`
                                  : ''
                              }
                            </div>
                            <div>
                              {
                                selectedOrderRef.current
                                  ?
                                    `${selectedOrderRef.current.delivery_address_city
                                        ? selectedOrderRef.current.delivery_address_city + ' '
                                        : ''}` +
                                    `${selectedOrderRef.current.delivery_address_state
                                        ? selectedOrderRef.current.delivery_address_state + ' '
                                        : ''}` +
                                    `${selectedOrderRef.current.delivery_address_postcode
                                        ? selectedOrderRef.current.delivery_address_postcode + ' '
                                        : ''}`
                                  : ''
                              }
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <Typography
                      noWrap
                      component="div"
                      className={classes.typographyStyle}
                      style={{ marginTop: '17px', paddingLeft: '16px' }}
                    >
                      <OverflowTip>Notes</OverflowTip>
                    </Typography>
                    <div style={{ backgroundColor: 'white', borderRadius: '10px', marginRight: '16px', paddingLeft: '16px' }}>
                      <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>    
                          <TextField
                            variant="outlined"
                            className={`${classes.textfield} ${classes.leftAlignTextfield}`}
                            value={notes ?? ''}
                            disabled
                            style={{ paddingRight: '16px' }}
                            name="notes"
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  {/* --------------------- TOTALS --------------------- */}
                  <Grid item xs={5} sm={5} md={5} lg={5} >
                    <Typography
                      noWrap
                      component="div"
                      className={classes.typographyStyle}
                      style={{ marginTop: '17px', paddingLeft: '16px' }}
                    >
                      <OverflowTip>Totals</OverflowTip>
                    </Typography>
                    <div style={{ backgroundColor: 'white', borderRadius: '10px' }}>
                    {showTotalDetails ?
                      <>
                        <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                          <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                            <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Subtotal</Typography>
                          </Grid>
                          <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                            <Typography className={classes.typographyStyle2} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                              <OverflowTip>
                                {isNaN(subTotalRef.current) ? 0 : '$' + formatNumber((Math.round(subTotalRef.current * 100) / 100).toFixed(2))}
                              </OverflowTip>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                          <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                            <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Tax (10% GST)</Typography>
                          </Grid>
                          <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                            <Typography className={classes.typographyStyle2} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                              <OverflowTip>
                                {isNaN(totalTaxRef.current) ? 0 : '$' + formatNumber((Math.round(totalTaxRef.current * 100) / 100).toFixed(2))}
                              </OverflowTip>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider style={{ marginLeft: '16px' }} />
                        <Grid container spacing={0} justifyContent="space-around" className={classes.gridContainer2} style={{ paddingRight: '16px' }}>
                          <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem} style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', justifyContent: 'center' }}>
                            <Typography
                              noWrap
                              component="div"
                              className={classes.typographyStyle4}
                              style={{ lineHeight: '22px' }}
                            >
                              <OverflowTip>Subtotal</OverflowTip>
                            </Typography>
                            <Typography
                              noWrap
                              component="div"
                              className={classes.typographyStyle2}
                              style={{ color: 'rgba(60, 60, 67, 0.6)' }}
                            >
                              <OverflowTip>Including tax</OverflowTip>
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                            <Typography
                              noWrap
                              component="div"
                              className={classes.typographyStyle4}
                              style={{ color: 'rgba(60, 60, 67, 0.6)' }}
                            >
                              <OverflowTip>
                                {isNaN(subTotalIncludingTaxRef.current) ? 0 : '$' + formatNumber((Math.round(subTotalIncludingTaxRef.current * 100) / 100).toFixed(2))}
                              </OverflowTip>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider style={{ marginLeft: '16px' }} />
                      </>
                    :
                      <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                          <Typography className={classes.typographyStyle4} style={{ color: 'black' }}>Subtotal</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                          <Typography className={classes.typographyStyle4} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                            <OverflowTip>
                              {isNaN(subTotalRef.current) ? 0 : '$' + formatNumber((Math.round(subTotalRef.current * 100) / 100).toFixed(2))}
                            </OverflowTip>
                          </Typography>
                        </Grid>
                      </Grid> 
                    }
                      <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                          <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Order Discount</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                          <Typography className={classes.typographyStyle2} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                            <OverflowTip>-${orderDiscountRef.current ? parseFloat(orderDiscountRef.current).toFixed(2) : parseFloat(0).toFixed(2)}</OverflowTip>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                          <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Shipping</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                          <Typography className={classes.typographyStyle2} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                            <OverflowTip>${shippingRef.current ? parseFloat(shippingRef.current).toFixed(2) : parseFloat(0).toFixed(2)}</OverflowTip>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider style={{ marginLeft: '16px' }} />
                      <Grid container spacing={0} justifyContent="space-around" className={classes.gridContainer2} style={{ paddingRight: '5px'}}>
                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                          <Typography
                            noWrap
                            component="div"
                            className={classes.typographyStyle4}
                            style={{ lineHeight: '22px' }}
                            >
                            <OverflowTip>Total</OverflowTip>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                          <Typography
                            noWrap
                            component="div"
                            className={classes.typographyStyle4}
                            style={{ backgroundColor: 'rgba(118, 118, 128, 0.12)', padding: '6px 11px', borderRadius: '6px' }}
                          >
                            <OverflowTip>
                              {isNaN(totalOrderItemRef.current) ? 0 : '$' + formatNumber((Math.round(totalOrderItemRef.current * 100) / 100).toFixed(2))}
                            </OverflowTip>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </>
            }
            {/* -------------------- BUTTON -------------------- */}
            <ButtonsView
              selectedOrder={selectedOrderRef.current}
              loadOrderSelected={loadOrderSelected}
              disabledCheck={disabledCheck}
              delivery_address_check={delivery_address_check}
              selectedSupplier={selectedSupplier}
              selectedCustomer={selectedCustomer}
              editSubmit={editSubmit}
              setDataCustomEmail={setDataCustomEmail}
              dataCustomEmail={dataCustomEmailRef.current}
              customEmailLoading={customEmailLoading}
              setCustomEmailLoading={setCustomEmailLoading}
              setIsCustomEmail={setIsCustomEmail}
              isCustomEmail={isCustomEmailRef.current}
              closeCustomEmail={closeCustomEmail}
              editSave={editSave}
              loadingSubmit={loadingSubmit}
              add={add}
            />
          </> 
      }

      <Dialog
        isopen={`${openChangeLog}`}
        open={openChangeLog}
        fullWidth={true}
        onClose={() => setOpenChangeLog(false)}
        className={classes.DialogContent}
      >
        <DialogContent>
          <Grid container spacing={0}>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography noWrap component="div" className={classes.typographyStyle}>
                <OverflowTip>Date & Time</OverflowTip>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography noWrap component="div" className={classes.typographyStyle}>
                <OverflowTip>Status Update</OverflowTip>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography noWrap component="div" className={classes.typographyStyle}>
                <OverflowTip>User</OverflowTip>
              </Typography>
            </Grid>
          </Grid>
          {logDataLoading?
            <CircularProgress key='load-progress-sales-orders' className={classes.circular} style={{  color: '#67AC2D' }}/>
          :
            logData.length > 0
              ? logData.map((dataLog, index) => {
                return (
                  <Grid container spacing={0} key={index} className={classes.gridContainer3}>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <Typography noWrap component="div" className={classes.typographyStyle2} style={{ color: '#000000' }}>
                        <OverflowTip>{dataLog.created_on ? moment(dataLog.created_on).format('DD/MM/YYYY hh:mm A') : ''}</OverflowTip>
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <Typography noWrap component="div" className={classes.typographyStyle2} style={{ color: '#000000' }}>
                        <OverflowTip>{orderEventType ? orderEventType[dataLog.type] : dataLog.type}</OverflowTip>
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <Typography noWrap component="div" className={classes.typographyStyle2} style={{ color: '#000000' }}>
                        <OverflowTip>{dataLog.owner ? dataLog.owner.first_name : ''} {dataLog.owner ? dataLog.owner.last_name : ''}</OverflowTip>
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })
            : null
          }
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default SelectedOrderView