//export const Permission = (user.role.name === 'Administrator' || user.role.name === 'DigiTime Admin' || user.role.name === 'Company Admin'); 
const user = JSON.parse(window.localStorage.getItem('directus_user'));
const employee = JSON.parse(window.localStorage.getItem('directus_employee'));
const configuration = JSON.parse(window.localStorage.getItem('configuration'));

export function AdminPermission() {
  if (user && employee) {
    return (user.role.name === 'Administrator' || user.role.name === 'Company Admin' || employee.po_user_role === "super_admin");
  }
  else {
    return false;
  }
}

export function InvoiceAdminPermission() {
  if (user) {
    return (employee.po_user_role === "invoice_admin" || AdminPermission());
  }
  else {
    return false;
  }
}

export function POApproverPermission() {
  if (user) {
    return (employee.po_user_role === "po_approver" || InvoiceAdminPermission() || AdminPermission());
  }
  else {
    return false;
  }
}

export function POApproverPermissionOnly() {
  if (user) {
    return (employee.po_user_role === "po_approver" && !InvoiceAdminPermission());
  }
  else {
    return false;
  }
}

export function POCreatorPermission() {
  if (user) {
    return (employee.po_user_role === "po_creator" || POApproverPermission || InvoiceAdminPermission() || AdminPermission());
  }
  else {
    return false;
  }
}

export function ShowInvoiceTab() {
  if (configuration) {
    return (configuration.show_invoices === true ? true : false);
  }
  else {
    return false;
  }
}