import React, { useState, useEffect, useRef } from 'react';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "12px",
    maxWidth: 500,
    backgroundColor: "grey"
  }
})(Tooltip);

export const OverflowTip = ({ children }) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef();
  useEffect(() => {
    setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
  }, [children]);
  return (
    <CustomTooltip arrow title={children} disableHoverListener={!isOverflowed} >
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </div>
    </CustomTooltip>
  );
};