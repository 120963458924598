import React from 'react';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

export const CloseSnackbarAction = (snackbarId) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <>
      <div onClick={() => { closeSnackbar(snackbarId) }} style={{ border: "none", background: "transparent", marginRight: "10px", cursor: "pointer", width: "25px", minWidth: "25px" }}>
        <div style={{ position: "absolute", right: "15px", top: "15px", marginTop: "-3px" }}>
          <CloseIcon style={{ width: "20px" }} />
        </div>
      </div>
    </>
  )
}