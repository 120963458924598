import React from 'react';

const Logo = (props) => {
  return (
    <img
      alt="Logo"
      src={window.location.hostname.search('digiorder') === -1?"/static/images/interax_order_logo.png":"/static/images/gt_logo.png"}
      {...props}
    />
  );
};

export default Logo;
