import 'react-perfect-scrollbar/dist/css/styles.css';
import React, {useEffect} from 'react';
import {useRoutes} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core';
import EventEmitter from './utils/EventEmitter';
import GlobalStyles from 'src/components/GlobalStyles';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import * as sessions from 'src/utils/sessions';
import './App.css';

Bugsnag.start({
  apiKey: '03acb8dec271d8a0e4c30fbd10727a4f',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NODE_ENV || process.env.REACT_APP_CUSTOM_NODE_ENV,
  user: {
    id: sessions.getEmployee() != null && sessions.getEmployee().id ? sessions.getEmployee().id : null,
    code: sessions.getEmployee() != null && sessions.getEmployee().code ? sessions.getEmployee().code : null,
  }
})

window.addEventListener('popstate', (e) => {
  EventEmitter.emit('navigation_changes'); 
});

const App = () => {
  useEffect(() => {
    sessions.getEmployee(true);
    sessions.getUser(true);
    sessions.redirectLogin();
  });

  const routing = useRoutes(routes);

  window.getCookie = name => {
    const value = `; ${window.document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2)
      return parts
        .pop()
        .split(';')
        .shift();
  };

  window.setCookie = (name, value, days) => {
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    window.document.cookie = name + '=' + (value || '') + expires + '; path=/';
  };

  if (
    !window.localStorage.getItem('directus_user') &&
    !window.location.pathname.startsWith('/sign-in') &&
    !window.location.pathname.startsWith('/passwordreset')
    // !window.location.pathname.startsWith('/sign-up')
  ) {
    window.location.href = `//${window.location.hostname}:${window.location.port}/sign-in`;
    return;
  } else {
    sessions.getEmployee(true);
    sessions.getUser(true);
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles/>
      {routing}
    </ThemeProvider>
  );
};

export default App;
