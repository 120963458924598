import React, { useEffect, useRef } from 'react';
import { makeStyles, withStyles, Button, Menu, Paper, } from "@material-ui/core";
import { styled } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { createMuiTheme } from '@material-ui/core/styles';

export const filterOption = (option) => {
  return option.data.value.terms && option.data.value.terms.length > 4;
};

export const NumResultsHeader = ({ children, ...other }) => {
  const paperRef = useRef();

  return (
    <div style={{ background: 'white', borderRadius: '0 0 5px 5px', marginLeft:'-105px' }}>
      <Paper {...other} ref={paperRef}>
        {children}
      </Paper>
    </div>
  );
};

export const NumResultsHeaderDescription = ({ children, dataItem, collection_data, ...other }) => {
  const headerRef = useRef();
  const countRef = useRef();
  const paperRef = useRef();

  useEffect(() => {
    const numOptions = paperRef.current.querySelectorAll('li[data-option-index]').length;
    countRef.current.innerHTML = numOptions;
    if (numOptions > 0) {
      headerRef.current.style.display = 'block';
    } else {
      headerRef.current.style.display = 'none';
    }
  });

  var total_number = 0;

  if (collection_data) {
    total_number = collection_data.length;
  }

  return (
    <div style={{ background: 'white', borderRadius: '0 0 5px 5px', marginLeft:'-105px' }}>
      <Paper {...other} ref={paperRef}>
        {children}
      </Paper>
      <div ref={headerRef} style={{ display: 'none', textAlign: 'right', padding: '5px' }}>
        Showing <span ref={countRef}></span> of {total_number}
      </div>
    </div>
  );
};

export const NumResultsHeaderItemDescription = ({ children, dataItem, collection_data, ...other }) => {
  const headerRef = useRef();
  const countRef = useRef();
  const paperRef = useRef();

  useEffect(() => {
    const numOptions = paperRef.current.querySelectorAll('li[data-option-index]').length;
    countRef.current.innerHTML = numOptions;
    if (numOptions > 0) {
      headerRef.current.style.display = 'block';
    } else {
      headerRef.current.style.display = 'none';
    }
  });

  var total_number = 0;

  if (collection_data) {
    total_number = collection_data.length;
  }

  return (
    <div style={{ background: 'white', borderRadius: '0 0 5px 5px', marginLeft:'-145px' }}>
      <Paper {...other} ref={paperRef}>
        {children}
      </Paper>
      <div ref={headerRef} style={{ display: 'none', textAlign: 'right', padding: '5px' }}>
        Showing <span ref={countRef}></span> of {total_number}
      </div>
    </div>
  );
};

export const theme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#67AC2D',
      },
    },
    MuiPickersDay: {
      day: {
        color: '#000',
      },
      daySelected: {
        backgroundColor: '#67AC2D',
        '&:hover': {
          backgroundColor: '#92cf5e',
        },
      },
      current: {
        color: '#67AC2D',
      },
    },
    MuiPickersYear: {
      yearSelected: {
        color: '#67AC2D',
        '&:hover': {
          color: '#67AC2D !important',
        },
      },
    },
    MuiIconButton: {
      root: {
        color: '#67AC2D',
        padding: '0px',
      },
    },
    MuiOutlinedInput: {
      root: {
        height: '34px',
        borderRadius: '6px',
        fontSize: '15px !important',
        fontWeight: '400 !important',
        letterSpacing: '-0.4px !important',
        color: 'rgba(60, 60, 67, 0.6) !important',
        borderColor: 'red',
        '& fieldset': {
          borderColor: '#B9B9BB',
          transition: 'border-color 0.3s ease',
        },
        '&:hover fieldset': {
          borderColor: '#82CB43 !important',
        },
        '&:focus-within fieldset': { 
          borderColor: '#82CB43 !important',
        },
      },
      input: {
        textAlign: 'right',
        padding: '0px 16px',
      },
    },
    MuiButton: {
      textPrimary: {
        color: '#67AC2D',
      },
      textSecondary: {
        color: '#67AC2D',
      },
    },
    MuiTextField: {
      root: {
        width: '100%',
      },
    },
  },
});

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
  },
  leftSide: {
    minWidth: '390px',
    maxWidth: '390px',
    padding: '0px 16px',
    boxShadow: '2px 0px 4px 0px #0000001A',
    height: '100vh',
    minHeight: '100vh',
    maxHeight: '100vh',
    overflowY: 'auto',
  },
  rightSide: {
    maxWidth: '805px',
    width: '805px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '19px 16px 99px',
    height: '100vh',
    minHeight: '100vh',
    maxHeight: '100vh',
    overflowY: 'auto',
  },
  headerStyle: {
    paddingLeft: '5px',
    fontSize: '20px',
    fontWeight: '590',
  },
  arrowDownWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    paddingRight: '13px'
  },
  gridContainer: {
    padding: '0px 16px',
    height: '44px',
    minHeight: '44px',
  },
  gridContainer2: {
    padding: '0px 16px',
    height: '60px',
    minHeight: '60px',
  },
  gridContainer3: {
    height: '30px',
    minHeight: '30px',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  textInput: {
    background: 'white',
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    borderRadius: "10px",
    fontSize: "15px",
    lineHeight: '22px',
    cursor: "pointer",
    height: "44px",
    paddingLeft: "16px",
    paddingRight: "16px",
    alignItems: "center",
  },
  textInput2: {
    background: 'white',
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    borderRadius: "10px",
    fontSize: "15px",
    lineHeight: '22px',
    cursor: "pointer",
    height: "44px",
    paddingLeft: "16px",
    alignItems: "center",
  },
  textInputValue: {
    overflowY: "hidden",
    width: "calc(100% - 20px)",
    fontWeight: "400",
    textOverflow: "ellipsis",
    overflowX: "hidden",
    whiteSpace: "nowrap",
    fontSize: '15px',
    lineHeight: '22px',
    letterSpacing: '-0.4px'
  },
  typographyStyle: {
    fontWeight: '590',
    fontSize: '15px',
    color: 'rgba(60, 60, 67, 0.6)',
    lineHeight: '20px',
    marginBottom: '7px',
    letterSpacing: '-0.4px'
  },
  typographyStyle2: {
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '20px',
    letterSpacing: '-0.4px',
    overflowX: 'hidden',
    textOverflow: 'ellipsis'
  },
  typographyStyle3: {
    fontWeight: '400',
    fontSize: '17px',
    lineHeight: '22px',
    letterSpacing: '-0.4px',
    overflowX: 'hidden',
    textOverflow: 'ellipsis'
  },
  typographyStyle4: {
    color: '#000000',
    fontWeight: '700',
    fontSize: '15px',
    lineHeight: '22px',
    overflowX: 'hidden',
    textOverflow: 'ellipsis'
  },
  typographyStyle5: {
    fontWeight: '700',
    fontSize: '17px',
    lineHeight: '22px',
    letterSpacing: '-0.4px',
    overflowX: 'hidden',
    textOverflow: 'ellipsis'
  },
  autocomplete: {
    borderRadius: '10px',
    height: 44,
    display: 'flex',
    alignItems: 'center',
    '& .MuiAutocomplete-endAdornment': {
      position: 'static !important',
      paddingLeft: '6px'
    },
    '& .MuiAutocomplete-inputRoot': {
      padding: '0px 0px 3px 0px !important',
    },
    "& .MuiInput-root": {
      "&:before": {
        borderColor: "rgba(0, 0, 0, 0.24)",
      },
      "&:after": {
        borderWidth: "1px",
      },
    },
    '& .MuiOutlinedInput-root': {
      height: 44,
      fontFamily: 'SF Pro !important',
      fontSize: '15px !important',
      fontWeight: '400 !important',
      letterSpacing: '-0.4px !important',
      padding: '0px 16px 0px 16px !important',
      '& fieldset': {
        border: 'none',
      },
    },
    '& .MuiAutocomplete-input': {
      padding: '0px !important',
    },
    '& .MuiAutocomplete-listbox': {
      backgroundColor: 'green !important',
    }
  },
  requiredAutoComplete: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '6px !important',
        borderColor: 'red !important',
      },
      '&:hover': {
        '& fieldset': {
          borderColor: 'red !important'
        }
      },
      '&:focus-within': { 
        '& fieldset': {
          border: '1px solid red !important'
        }
      },
    },
  },
  textFieldRequired: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '6px !important',
        borderColor: 'red !important',
      },
      '&:hover': {
        '& fieldset': {
          borderColor: 'red !important'
        }
      },
      '&:focus-within': { 
        '& fieldset': {
          border: '1px solid red !important'
        }
      },
    },
  },
  autocompleteEditable: {
    height: '34px',
    display: 'flex',
    alignItems: 'center',
    '& .MuiAutocomplete-endAdornment': {
      position: 'static !important',
      paddingLeft: '6px'
    },
    '& .MuiAutocomplete-inputRoot': {
      padding: '0px 0px 3px 0px !important',
    },
    '& .MuiOutlinedInput-root': {
      height: '34px',
      fontFamily: 'SF Pro !important',
      fontSize: '15px !important',
      fontWeight: '400 !important',
      letterSpacing: '-0.4px !important',
      padding: '0px 16px 0px 11px !important',
      '& fieldset': {
        borderRadius: '6px',
        borderColor: '#B9B9BB',
        transition: 'border-color 0.3s ease'
      },
      '&:hover': {
        '& fieldset': {
          borderColor: '#82CB43'
        }
      },
      '&:focus-within': { 
        '& fieldset': {
          border: '1px solid #82CB43'
        }
      },
    },
    '& .MuiAutocomplete-input': {
      padding: '0px !important',
      maxHeight: '36px',
      overflowY: 'auto !important',
    },
  },
  paperstyle: {
    width: '285px !important'
  },
  menuItem: {
    backgroundColor: 'white',
  },
  selectedMenuItem: {
    backgroundColor: 'rgba(130, 203, 67, 0.15) !important',
  },
  textfield: {
    width: '100%',
    borderRadius: '10px',
    height: '44px',
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '0px !important'
    },
    '& .MuiInputBase-input': {
      textOverflow: 'ellipsis',
      color: 'rgba(60, 60, 67, 0.6)',
      textAlign: 'right'
    },
    '& .MuiOutlinedInput-input': {
      paddingRight: '0px',
    },
    '& .MuiOutlinedInput-root': {
      height: '44px',
      fontFamily: 'SF Pro !important',
      fontSize: '15px !important',
      fontWeight: '400 !important',
      letterSpacing: '-0.4px !important',
      color: 'black !important',
      '& fieldset': {
        border: 'none',
      },
    },
  },
  deliveryaddressstyle: {
    fontFamily: 'SF Pro !important',
    fontSize: '15px !important',
    fontWeight: '400 !important',
    letterSpacing: '-0.4px !important',
    color: 'rgba(60, 60, 67, 0.6) !important',
    padding: '5px 16px 5px 0px !important',
    textAlign: 'right',
  },
  textfield2: {
    width: '100%',
    height: '44px',
    '& .MuiInputBase-input': {
      color: 'rgba(60, 60, 67, 0.6)',
      textAlign: 'right'
    },
    '& .MuiOutlinedInput-root': {
      height: '44px',
      fontFamily: 'SF Pro !important',
      fontSize: '15px !important',
      fontWeight: '400 !important',
      letterSpacing: '-0.4px !important',
      color: 'black !important',
      padding: '0px 0px 0px 16px !important',
      '& fieldset': {
        border: 'none',
      },
    },
  },
  textfield2Editable: {
    width: '100%',
    height: '34px',
    '& .MuiInputBase-input': {
      color: 'rgba(60, 60, 67, 0.6)',
      textAlign: 'right'
    },
    '& .MuiOutlinedInput-root': {
      height: '34px',
      fontFamily: 'SF Pro !important',
      fontSize: '15px !important',
      fontWeight: '400 !important',
      letterSpacing: '-0.4px !important',
      color: 'black !important',
      padding: '0px 0px 0px 11px !important',
      '& fieldset': {
        borderRadius: '6px',
        borderColor: '#B9B9BB',
        transition: 'border-color 0.3s ease'
      },
      '&:hover': {
        '& fieldset': {
          borderColor: '#82CB43'
        }
      },
      '&:focus-within': { 
        '& fieldset': {
          border: '1px solid #82CB43'
        }
      },
    },
  },
  leftAlignTextfield: {
    '& .MuiInputBase-input': {
      color: 'black !important',
      textAlign: 'left !important',
      padding: '0px'
    },
  },
  leftAlignMultilineTextfield: {
    width: '100%',
    '& .MuiInputBase-input': {
      color: 'black !important',
      textAlign: 'left !important',
      padding: '0px',
      fontFamily: 'SF Pro',
      fontWeight: '400',
      fontSize: '15px',
      letterSpacing: '-0.4px',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
  },
  outlinedTextfield: {
    height: '60px !important',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
  },
  outlinedTextfieldEditable: {
    height: '49px !important',
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '6px',
      borderColor: '#82CB43'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #0D46A0'
    },
  },
  textfield2EditableDisabled: {
    width: '100%',
    height: '34px',
    '& .MuiInputBase-input': {
      color: 'rgba(60, 60, 67, 0.6)',
      textAlign: 'right'
    },
    '& .MuiOutlinedInput-root': {
      height: '34px',
      fontFamily: 'SF Pro !important',
      fontSize: '15px !important',
      fontWeight: '400 !important',
      letterSpacing: '-0.4px !important',
      color: 'black !important',
      padding: '0px 0px 0px 11px !important',
      '& fieldset': {
        border: 'none',
      },
    },
  },
  textfield3: {
    width: '100% !important',
    '& .MuiInputBase-input': {
      padding: '0px',
    },
    '& .MuiOutlinedInput-root': {
      height: '34px',
      fontFamily: 'SF Pro !important',
      fontSize: '15px !important',
      fontWeight: '400 !important',
      letterSpacing: '-0.4px !important',
      color: 'black !important',
      padding: '0px 5px',
      '& fieldset': {
        borderRadius: '6px',
        borderColor: '#B9B9BB',
        transition: 'border-color 0.3s ease'
      },
      '&:hover': {
        '& fieldset': {
          borderColor: '#82CB43'
        }
      },
      '&:focus-within': { 
        '& fieldset': {
          border: '1px solid #82CB43'
        }
      },
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
  },
  textfield3ItemDetails: {
    '& .MuiOutlinedInput-root': {
      padding: '0px 11px !important',
    },
  },
  textfield3Required: {
    width: '100% !important',
    '& .MuiInputBase-input': {
      padding: '0px',
    },
    '& .MuiOutlinedInput-root': {
      height: '34px',
      fontFamily: 'SF Pro !important',
      fontSize: '15px !important',
      fontWeight: '400 !important',
      letterSpacing: '-0.4px !important',
      color: 'black !important',
      padding: '0px 5px',
      '& fieldset': {
        borderRadius: '6px',
        borderColor: 'red',
        transition: 'border-color 0.3s ease'
      },
      '&:hover': {
        '& fieldset': {
          borderColor: 'red'
        }
      },
      '&:focus-within': { 
        '& fieldset': {
          border: '1px solid red'
        }
      },
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
  },
  gridPaddingRight: {
    paddingRight: '5px'
  },
  hideIcon: {
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: '0px !important'
    },
  },
  datepicker: {
    width: '100%',
    margin: '0px !important',
    backgroundColor: 'white',
    height: '44px',
    borderRadius: '10px',
    '& .MuiIconButton-root': {
      padding: '0px'
    },
    '& .MuiInputBase-input': {
      textAlign: 'right',
    },
    '& .MuiOutlinedInput-root': {
      fontSize: '15px !important',
      fontWeight: '400 !important',
      letterSpacing: '-0.4px !important',
      color: 'rgba(60, 60, 67, 0.6) !important',
      height: '44px',
      '& fieldset': {
        border: 'none',
      },
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '14px !important'
    },
  },
  DialogContent: {
    '& .MuiDialogContent-root': {
      minHeight: '167px',
      maxHeight: 'calc(100vh - 300px)',
      borderRadius: '10px',
      padding: '10px 20px !important'
    },
    '& .MuiDialog-paperFullWidth': {
      borderRadius: '10px !important',
      width: 'calc(100% - 661px)',
      marginLeft: '165px',
    },
    '& .MuiDialog-container': {
      height: 'auto !important'
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '100%'
    },
    '& .MuiDialog-paper': {
      marginTop: '160px !important'
    }
  },
  DialogRejectStyle: {
    '& .MuiDialogContent-root': {
      padding: '18px'
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '815px'
    },
    '& .MuiDialog-paperFullWidth': {
      borderRadius: '10px !important',
    },
  },
  DialogApproveStyle: {
    '& .MuiDialogContent-root': {
      padding: '18px'
    },
    '& .MuiDialog-paperWidthSm': {
      // maxWidth: '815px'
    },
    '& .MuiDialog-paperFullWidth': {
      borderRadius: '10px !important',
    },
  },
  dialogCloseBtn: {
    width: '35px',
    height: '35px',
    position: 'absolute',
    right: '5px',
    top: '5px'
  },
  dialogCloseBtn2: {
    width: '35px',
    height: '35px',
    position: 'absolute',
    right: '5px',
    top: '5px',
    bottom: '16px'
  },
  DialogStatusStyle: {
    '& .MuiDialogContent-root': {
      minHeight: '167px',
      maxHeight: 'calc(100vh - 300px)',
      borderRadius: '10px',
      padding: '10px 20px !important'
    },
    '& .MuiDialogTitle-root': {
      textAlign: 'center',
      padding: '0px 0px 20px 0px !important',
      marginTop: '-10px'
    },
    '& .MuiDialogActions-root': {
      padding: '0px 32px 16px 32px !important'
    },
    '& .MuiPaper-rounded': {
      borderRadius: '10px !important'
    },
    '& .MuiButton-root': {
      textTransform: 'capitalize !important'
    },
    '& .MuiTypography-h6': {
      fontSize: '15px !important',
      fontWeight: '400 !important',
      letterSpacing: '0px !important',
      lineHeight: '22px'
    }
  },
  circular: {
    color: '#5bfa17',
    marginLeft: '45%'
  },
  form: {
    display: 'inline',
    width: '100%'
  },
  input: {
    display: 'none',
  },
  buttonCustom: {
    borderRadius: '12px',
    padding: '14px 20px',
    textTransform: 'none',
    letterSpacing: '-0.4px',
    color: 'rgba(55, 67, 85, 1)',
    backgroundColor: 'rgba(81, 101, 115, 0.15)',
    boxShadow: 'none',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: [
      'SF Pro',
      'Arial',
      'sans-serif',
    ].join(','),
    '&:hover': {
      backgroundColor: '#BADF9C',
      boxShadow: 'none',
    },
  },
  fileInputLabel: {
    display: 'flex',
    width: '100%',
    cursor: 'pointer',
    padding: '6px 16px'
  },
  menuitem2: {
    fontWeight: 400,
    fontSize: '15px',
    letterSpacing: '-0.4px',
    lineHeight: '22px',
    fontFamily: [
      'SF Pro',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  menuitem: {
    height: '46px',
    fontWeight: 400,
    fontSize: '15px',
    letterSpacing: '-0.4px',
    lineHeight: '22px',
    fontFamily: [
      'SF Pro',
      'Arial',
      'sans-serif',
    ].join(','),
    padding: '6px 16px !important'
  },
  listStyle: {
    '& .MuiListItem-gutters': {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  generate_invoice_button: {
    fontSize: "18px !important",
    color: "#616161",
    "&:hover": {
      color: "#82CB43"
    }
  },
  Appbar: {
    backgroundColor: '#E2E2E7',
    color: '#333',
    minHeight: '28px',
    height: "28px",
    maxHeight: "28px",
    paddingBottom: '10px',
    borderRadius: '6px',
    boxShadow: '0px 0px !important',
    '& .Mui-selected': {
      backgroundColor: 'white !important',
      borderRadius: '6px !important',
      margin: '2.5px 2.5px 2.5px 4.5px !important',
      padding: '2.5px 12px',
      height: '23px',
      maxHeight: '23px',
      minHeight: '23px'
    },
    '& .MuiTab-root': {
      height: '23px',
      maxHeight: '23px',
      minHeight: '23px',
      margin: '2.5px',
      padding: '2.5px 12px',
      width: '48%',
    },
    '& .MuiTabs-root': {
      height: '28px',
      minHeight: '28px',
    },
    '& .MuiTabs-scroller': {
      position: 'initial !important',
      height: '28px',
    },
  },
  tab: {
    '& .MuiBox-root': {
      padding: '10px 0px 0px 0px',
    },
  },
  customfontstyle: {
    fontFamily: 'SF Pro',
    fontWeight: 'bold',
    minHeight: "15px",
    textTransform: 'capitalize'
  },
  badgeTotal: {
    display: 'inline-flex',
    marginLeft: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FC6868',
    width: '24px',
    height: '24px',
    color: 'white',
    borderRadius: '50%',
    fontSize: '17px',
    fontWeight: '400'
  }
}));

export const CustomButton = styled(Button)({
  fontWeight: 400,
  fontSize: '15px',
  boxShadow: 'none',
  lineHeight: '20px',
  borderRadius: '40px',
  padding: '0px 14px',
  textTransform: 'none',
  height: '34px',
  letterSpacing: '-0.4px',
  color: 'rgba(55, 67, 85, 1)',
  backgroundColor: 'rgba(81, 101, 115, 0.15)',
  fontFamily: [
    'SF Pro',
    'Arial',
    'sans-serif',
  ].join(','),
  '&:hover': {
    backgroundColor: 'rgba(81, 101, 115, 0.3)',
    boxShadow: 'none',
  },
});

export const CustomButton2 = styled(Button)({
  fontWeight: 400,
  fontSize: '15px',
  boxShadow: 'none',
  lineHeight: '20px',
  borderRadius: '40px',
  padding: '0px 14px',
  textTransform: 'none',
  height: '34px',
  letterSpacing: '-0.4px',
  color: 'white',
  backgroundColor: 'rgb(103, 172, 45)',
  fontFamily: [
    'SF Pro',
    'Arial',
    'sans-serif',
  ].join(','),
  '&:hover': {
    backgroundColor: 'rgb(80, 134, 36)',
    boxShadow: 'none',
  },
  '& .MuiButton-startIcon': {
    marginRight: '4px !important'
  }
});

export const DeleteButton = styled(Button)({
  fontWeight: 400,
  fontSize: '15px',
  boxShadow: 'none',
  lineHeight: '20px',
  borderRadius: '40px',
  padding: '0px 14px',
  textTransform: 'none',
  height: '34px',
  letterSpacing: '-0.4px',
  color: 'white',
  backgroundColor: '#E87777',
  fontFamily: [
    'SF Pro',
    'Arial',
    'sans-serif',
  ].join(','),
  '&:hover': {
    backgroundColor: '#cd6666',
    boxShadow: 'none',
  },
  '& .MuiButton-startIcon': {
    marginRight: '4px !important'
  }
});

export const StyledMenu = withStyles({
  paper: {
    width: '200px',
    background: '#F6F6F6',
    boxShadow: '0px 0px 32px 0px rgba(0, 0, 0, 0.2)',
    borderRadius: '12px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    {...props}
  />
));

export const getStatusIconPath = status => {
  let statusIconPath = '';

  switch (status) {
    case 'draft':
      statusIconPath = "/static/images/icons/draft.svg";
      break;
    case 'completed':
      statusIconPath = "/static/images/icons/complete.svg";
      break;
    case 'sent':
      statusIconPath = "/static/images/icons/sent.svg";
      break;
    case 'cancelled':
      statusIconPath = "/static/images/icons/cancelled.svg";
      break;
    case 'awaiting_approval':
      statusIconPath = "/static/images/icons/awaiting_approval.svg";
      break;
    case 'rejected':
      statusIconPath = "/static/images/icons/rejected.svg";
      break;
    default:
      statusIconPath = "";
  }

  return statusIconPath;
}

export const LoadingButtonCustom = styled(LoadingButton)(({ theme }) => ({
  width: '100%',
  borderRadius: '10px',
  height: '44px',
  boxShadow: 'none',
  color: 'rgba(60, 60, 67, 0.9)',
  borderColor: 'rgba(60, 60, 67, 0.6)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderColor: 'rgba(60, 60, 67, 0.6)',
  },
  '& .MuiLoadingButton-loadingIndicator': {
    right: '118px !important'
  }
}));

export const CancelButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#e0e0e0de',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#bdbdbdde',
    },
  },
})(Button);

export const SubmitButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#22a033',
    },
  },
})(Button);

export const AddItemButton = withStyles({
  root: {
    border: 'none',
    backgroundColor: 'rgba(81, 101, 115, 0.15)',
    padding: '7px 14px !important',
    textTransform: 'capitalize',
    color: '#37455',
    borderRadius: '12px',
    '&:hover': {
      border: 'none',
      backgroundColor: 'rgba(81, 101, 115, 0.15)',
      borderRadius: '12px',

    },
  },
})(Button);