import { makeStyles, withStyles, Button } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  grid: {
    width: '291px',
    height: '36px',
    borderRadius: '8px',
    paddingTop: '3px',
  },
  left: {
    float: 'left',
    paddingLeft: '8px',
    paddingTop: '4px',
  },
  right: {
    float: 'right',
    paddingRight: '3px',
  },
  rightpadding: {
    paddingRight: '7px'
  },
  leftpadding: {
    paddingLeft: '12px',
  },

  paper: {
    paddingRight: '12px',
    paddingBottom: '3px',
    paddingTop: '3px',
    width: '130px',
    height: '30px',
    textAlign: 'right'
  },
  arrowforward: {
    marginRight: '-77px',
    marginLeft: '-38px',
    marginTop: '-12px',

  },
  arrowButton: {
    borderRadius: '10px',
    marginTop: '2px',
  },

  floatright: {
    textAlign: 'right',
    paddingRight: '14px'
  },

  //icon button
  iconbuttonarea: {
    borderRadius: '10px',
    width: '36px',
    height: '36px',
    marginLeft: '6px',
    marginTop: '-6px',
  },

  //cancel button
  CancelIcon: {
    color: 'white',
    borderRadius: '10px'
  },
  CancelIconButton: {
    borderRadius: '10px',
    width: '36px',
    height: '36px',
    marginTop: '-3px',
  },

  //keyboard arrow
  KeyboardArrowDownIcon: {
    borderRadius: '10px',
    width: '36px',
    height: '36px',
    marginLeft: '6px',
    marginTop: '-6px'
  },

  //add button
  buttonadditem: {
    backgroundColor: '#f2f2f2',
    marginRight: '7px',
    borderRadius: '14px',
    height: '35px',
    marginTop: '-6px'
  },
  buttonadditem2: {
    backgroundColor: '#f2f2f2',
    marginLeft: '7px',
    borderRadius: '14px',
    height: '35px',
    marginTop: '0px',
    paddingBottom: "3px"
  },
  buttonsubmit: {
    backgroundColor: '#f2f2f2',
    borderRadius: '14px',
    height: '35px',
    marginTop: '0px',
    paddingTop: "10px"
  },

  buttonChangeLog: {
    backgroundColor: '#f2f2f2',
    borderRadius: '14px',
    height: '30px',
    marginTop: '-3px'
  },

  button: {
    backgroundColor: '#f2f2f2',
    marginRight: '10px',
    borderRadius: '14px',
    height: '36px',
    marginTop: '-3px',
  },
  //status area
  statusarea: {
    height: '36px',
    paddingLeft: '10px',
    marginTop: '-3px',
    paddingTop: '8px',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    textTransform: 'capitalize'
  },

  statusarea2: {
    height: '36px',
    paddingLeft: '10px',
    marginTop: '-6px',
    paddingTop: '8px',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    textTransform: 'capitalize'
  },

  //amount
  amountarea: {
    width: '80%',
    height: '36px',
    paddingTop: '6px',
    marginTop: '-3px',
    paddingRight: '3px',
    paddingLeft: '3px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
  },

  amountarea2: {
    width: '80%',
    height: '36px',
    paddingTop: '6px',
    marginTop: '-6px',
    paddingRight: '3px',
    paddingLeft: '3px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  amountdetail: {
    height: '30px',
    marginTop: '-3px',
    textAlign: 'right',
    paddingRight: '15px',
    paddingTop: '4px',
  },

  //internal code
  internalcode: {
    marginLeft: '13px',
    width: '240px'
  },
  internalcodeleftpadding: {
    paddingLeft: '47px',
  },

  unitpriceleftpadding: {
    textAlign: 'right',
    paddingRight: '73px',
  },
  unitpricecolumn: {
    height: '30px',
    borderRadius: '8px',
    marginRight: '6px',
    display: 'flex',
    backgroundColor: 'transparent'
  },
  unitprice_input: {
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      textAlign: "right"
    }
  },

  qty: {
    width: '193px',
    height: '30px'
  },

  //order
  orderlist: {
    padding: '7px 0px 6px 16px',
  },

  orderitemdetail: {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    // paddingLeft: '16px',
    // paddingBottom: '6px',
    // paddingTop: '6px',
  },
  orderFooter: {
    marginTop: '8px',
    '& .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-3.MuiGrid-grid-md-4.MuiGrid-grid-lg-4': {
      marginBottom: 'auto',
    }
  },
  orderitem: {
    borderRadius: '8px',
    boxShadow: '1px 1px 3px 3px #9E9E9E',
    marginTop: '6px',
    paddingBottom: '6px',
    paddingRight: '6px',
    paddingLeft: '6px',
  },
  orderitemcontent: {
    padding: '7px 0px 6px 22px',
    alignItems: 'center',
    marginLeft: '6px',
    marginTop: '-6px'
  },

  //item area
  itemarea: {
    // paddingLeft: '16px',
    // paddingLeft: '10px',
    // paddingBottom: '3px',
    // paddingTop: '3px',
  },

  itemarealast: {
    // borderBottomLeftRadius: '8px',
    // borderBottomRightRadius: '8px',
    // paddingLeft: '10px',
    // paddingBottom: '3px',
    // paddingTop: '3px',
    paddingBottom: '6px',
  },

  itemcolumn: {
    height: '30px',
    borderRadius: '8px',
    // marginRight: '6px',
    backgroundColor: 'transparent'
  },

  itemcolumn2: {
    height: 'calc(100% - 6px)',
    borderRadius: '8px',
    marginRight: '6px',
    backgroundColor: 'white',
    padding: '4.5px 10px',
    '& .MuiTypography-body1': {
      overflowWrap: 'anywhere'
    }
  },

  //input
  input: {
    display: 'none',
  },

  notes: {
    marginLeft: '-1px',
    borderRadius: '8px',
    fontFamily: 'SF Pro',
    fontSize: '14px',
    borderColor: 'darkgrey'
  },
  email: {
    width: '295px',
    marginLeft: '39px',
  },
  description: {
    width: '70% !important',
  },

  //sent orders
  sentOrders: {
    borderRadius: '8px',
    boxShadow: '1px 1px 3px 1px rgba(0,0,0,0.3)',
    padding: '6px 6px 6px 6px',
  },

  //form
  form: {
    display: 'inline',
  },

  add: {
    width: '36px',
    height: '36px',
    marginBottom: '6px',
  },

  iconAdd: {
    color: '#39F',
    borderRadius: '19px'
  },

  categorydetail: {
    borderRadius: '8px',
    paddingLeft: '16px',
    paddingBottom: '3px',
    paddingTop: '5px',
    marginBottom: '10px',
    minHeight: '40px'
  },

  categorycolumn: {
    height: 'calc(100% - 2px)',
    borderRadius: '8px',
    marginRight: '6px',
    backgroundColor: 'white',
    padding: '3.5px 6px',
    '& .MuiTypography-body1': {
      overflowWrap: 'anywhere'
    }
  },

  orderitem_header: {
    paddingTop: "6px",
    paddingBottom: "6px",
    color: "#333"
  },

  logdata: {
    paddingTop: "3px",
    paddingBottom: "3px",
    color: "#333"
  },

  logdata_detail: {
    color: "#333",
  },

  logdata_detail_txt: {
    fontSize: "12px",
    lineHeight: "36px"
  },

  itemcategory_header: {
    width: "17%",
    background: "#8AA9CA",
    marginTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "18px",
    borderTopLeftRadius: "6px",
    color: "#FFF"
  },

  itemdetails_header: {
    width: 'calc(21% - 35px)',
    background: "#8AA9CA",
    marginTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "20px",
    borderTopRightRadius: "6px",
    color: "#FFF"
  },

  itemclose_header: {
    width: '35'
  },

  itemdetails: {
    width: 'calc(21% - 35px)',
    minHeight: '36px'
  },

  itemclose: {
    width: '35px'
  },

  internalcategory: {
    marginTop: "5px",
    color: "#FFF",
    fontSize: "14px"
  },

  orderitem_header_left: {
    lineHeight: "24px",
    fontWeight: "600"
  },

  orderitem_header_right: {
    lineHeight: "24px",
    fontWeight: "500"
  },

  itemcategory_content: {
    backgroundColor: '#8AA9CA',
    paddingLeft: "5px",
  },

  itemdetails_content: {
    width: "100%",
    height: "100%",
    backgroundColor: '#8AA9CA',
    paddingLeft: "7px",
    paddingRight: "6px"
  },
  firstnote: {
    borderRadius: '8px',
    marginTop: '6px',
    padding: '6px 0px 6px 16px'
  },

  cc_email: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      height: "35px"
    }
  },

  requiredAutoComplete: {
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
      border: "1px solid red"
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot input': {
      marginTop: "-5px"
    }
  },

  textField: {
    '& .MuiInputBase-input': {
      marginTop: "0px !important"
    },
  },

  textFieldRequired: {
    '& .MuiInputBase-input': {
      marginTop: "0px !important"
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      border: "1px solid red"
    }
  },

  iconbuttonarea2: {
    borderRadius: '10px',
    width: '36px',
    height: '36px',
    marginLeft: '6px',
    marginTop: '0px',
  },
  generate_invoice_button: {
    marginRight: "5px",
    fontSize: "18px !important",
    color: "#616161",
    "&:hover": {
      color: "#82CB43"
    }
  }
}));

export default useStyles;


export const CancelButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#e0e0e0de',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#bdbdbdde',
    },
  },
})(Button);

export const CancelledButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#ffa39e',
    color: '#ffffff',
    height: '30px',
    width: '150px',
    '&:hover': {
      backgroundColor: '#f08984',
    },
  },
})(Button);

export const CompletedButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#7cd183',
    color: '#ffffff',
    height: '30px',
    width: '150px',
    '&:hover': {
      backgroundColor: '#6abd7c',
    },
  },
})(Button);


export const DraftButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#8aa9ca',
    color: '#ffffff',
    height: '30px',
    width: '150px',
    '&:hover': {
      backgroundColor: '#6898cb',
    },
  },
})(Button);

export const SentButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#f9ebcc',
    height: '30px',
    width: '150px',
    '&:hover': {
      backgroundColor: '#fde9b9',
    },
  },
})(Button);

export const SubmitButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#22a033',
    },
  },
})(Button);