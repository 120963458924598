import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import useStateRef from "react-usestateref";
import EventEmitter from "src/utils/EventEmitter";
import directus from "../../services/directus";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { getEmployee } from "src/utils/sessions";
import { POApproverPermission, POCreatorPermission } from 'src/utils/Permission';
import { useStyles, getStatusIconPath, LoadingButtonCustom } from './style.js';
import { OverflowTip } from "src/components/OverflowTip";
import { Grid, Typography } from "@material-ui/core";
import { Divider, Skeleton } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AddIcon from '@mui/icons-material/Add';

var react_app_url = process.env.REACT_APP_URL;
if (window.location.hostname.search("interax") !== -1) {
  react_app_url = process.env.REACT_APP_URL_INTERAX;
}

function AllOrdersListView() {
  const classes = useStyles();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [initialized, setInitialized] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [search, setSearch, searchRef] = useStateRef("");
  const [page, setPage, pageRef] = useStateRef(1);
  const [limit, setLimit, limitRef] = useStateRef(100);
  const [totalPage, setTotalPage, totalPageRef] = useStateRef("");
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [orders, setOrders, ordersRef] = useStateRef(null);
  const [selectedOrderId, setSelectedOrderId, selectedOrderIdRef] = useStateRef(window.location.pathname.split('/').pop());
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [filterData, setFilterData, filterDataRef] = useStateRef({
    date: 6,
    date_range: [],
    order: ["all"],
    customer: ["0"],
    employee: ["0"],
    isMyOrder: POApproverPermission() ? false : true,
    isAnyPrice: true,
    price: { min: 0, max: 0 }
  });
  
  useEffect(() => {
    if(!searchRef.current) {
      if (localStorage.search_word) {
        const search_word = JSON.parse(localStorage.search_word);
        setSearch(search_word);
        EventEmitter.emit('reload_search', { value: search_word });
      }
    } else {
      EventEmitter.emit('reload_search', { value: searchRef.current });
      localStorage.setItem("search_word", JSON.stringify(searchRef.current));
    }

    return () => {
      localStorage.removeItem("search_word");
    };
  }, [window.location.pathname])

  useEffect(() => {
    setInitialPageAndLimit();
    if (!initialized) {
      setInitialized(true);
      loadFirst();
    }

    const onSearch = async eventData => {
      setSearch(eventData.text);
      setPage(1);
      localStorage.setItem("search_word", JSON.stringify(eventData.text));
      await loadOrders(eventData.text);
      EventEmitter.emit("searching_loading", {
        value: false
      });
    };
    const listener = EventEmitter.addListener('/sales-orders/:id', onSearch);

    const onLoadOrders = async eventData => {
      setInitialPageAndLimit();

      let load_status = true;
      if (eventData && eventData.loadStatus === false) {
        load_status = eventData.loadStatus;
      }

      await loadOrders('', false, load_status);
      
      if (eventData && eventData.value) {
        if (ordersRef.current && ordersRef.current.length > 0) {
          if (ordersRef.current[0].orderData.length > 0) {
            if (ordersRef.current[0].orderData[0].data.length > 0) {
              if (ordersRef.current[0].orderData[0].data[0]) {
                const firstOrder = ordersRef.current[0].orderData[0].data[0];
                navigate(`/sales-orders/${firstOrder.id}`);
                setSelectedOrderId(firstOrder.id);
                EventEmitter.emit("selected_sales_order");
              }
            }
          }
        } else {
          navigate(`/sales-orders/new`);
          setSelectedOrderId('');
          EventEmitter.emit("selected_sales_order");
        }
      }
    };
    const listener2 = EventEmitter.addListener('load_all_orders', onLoadOrders);

    const add = async () => {
      setSelectedOrderId('');
    };
    const listener3 = EventEmitter.addListener("addOrder", add);

    const onExportOrder = eventData => {
      exportOrder(eventData.text);
    };
    const listener4 = EventEmitter.addListener("export", onExportOrder);

    const navigationChanges = async () => {
      let pathname = window.location.pathname;
      if (pathname.includes('sales-orders')) {
        setSelectedOrderId(window.location.pathname.split('/').pop());
        await loadOrders();
      }
    };

    const listener5 = EventEmitter.addListener('navigation_changes', navigationChanges);

    return () => {
      listener.remove();
      listener2.remove();
      listener3.remove();
      listener4.remove();
      listener5.remove();
    };
  }, []);
  
  useEffect(() => {
    const onFilterData = async eventData => {
      setFilterData(eventData);
      localStorage.setItem("limit_and_page", JSON.stringify({page: 1, limit: 100}));
      setLimit(100);
      setPage(1);
      await loadOrders();
      EventEmitter.emit("searching_loading", {
        value: false
      });
    };
    const listener1 = EventEmitter.addListener("filter_data", onFilterData);

    return () => {
      listener1.remove();
      localStorage.removeItem('limit_and_page');
    };
  }, []);

  const setInitialPageAndLimit = () => {
    let pageAndLimitOnLocalStorage = null;
    if(localStorage.limit_and_page) {
      pageAndLimitOnLocalStorage = JSON.parse(localStorage.limit_and_page);
    }

    if (pageAndLimitOnLocalStorage) {
      setPage(pageAndLimitOnLocalStorage.page);
      setLimit(pageAndLimitOnLocalStorage.limit);
    } else if (state) {
      setPage(state.page);
      setLimit(state.limit);
    }
  }

  const loadFirst = () => {
    var filter_data = localStorage.filter_data;
    if (filter_data) {
      filter_data = JSON.parse(filter_data);
      if (!filter_data.price) {
        filter_data.price = {
          min: 0,
          max: 0
        };
        localStorage.setItem("filter_data", JSON.stringify(filter_data));
      }

      if (!filter_data.employee) {
        filter_data.employee = ["0"];
      }

      setFilterData(filter_data);
    }

    loadOrders();
  };

  const loadOrders = async (searchWord = '', loadMoreStatus = false, load_status = true) => {
    if (!loadMoreStatus && load_status) {
      setOrdersLoading(true);
    }

    //------------------ filter -------------- //
    let filterData = {};

    if (searchRef.current) {
      searchWord = searchRef.current;
    }
    
    var Orders = null;
    var order_date_value = { nnull: null };

    // date filter
    var index = filterDataRef.current.date;
    var start_date = moment().format("YYYY-MM-DD 00:00:00");
    var end_date = moment().format("YYYY-MM-DD 23:59:59");
    if (index === 2) {
      start_date = filterDataRef.current.date_range[0];
      end_date = filterDataRef.current.date_range[1];
    } else if (index === 3) {
      start_date = moment()
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment()
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
    } else if (index === 4) {
      start_date = moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
    } else if (index === 5) {
      start_date = moment()
        .subtract(29, "days")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment().format("YYYY-MM-DD 23:59:59");
    } else if (index === 6) {
      start_date = moment()
        .subtract(2, "months")
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment()
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
    }

    if (index !== 1) {
      order_date_value = { between: [start_date, end_date] };
    }

    //order status filter
    var order_index = filterDataRef.current.order;
    var status = { nnull: null };
    if (order_index[0] !== "all") {
      status = { in: order_index };
    }

    var employee_id = await getEmployee();

    //customer filter
    var customer_index = filterDataRef.current.customer;
    var customer = { nnull: null };
    var showOrder = false;

    //employee filter
    var employees_index = filterDataRef.current.employee;
    var employees = { nnull: null };
    if (employees_index[0] !== "0") {
      employees = { in: employees_index };
    }

    if (POCreatorPermission()) {
      showOrder = true;
    }

    if (customer_index[0] !== "0") {
      customer = { in: customer_index };
    }

    //isMyOrder filter
    var isMyOrder = filterDataRef.current.isMyOrder;
    var includeArchive = filterDataRef.current.includeArchivedOrders;
    
    var employee = { nnull: null };

    if (isMyOrder) {
      employee = { eq: employee_id.id };
    }

    if (order_index[0] !== "all") {
      filterData["sales_orders.status"] = status;
    } else {
      filterData = { ["sales_orders.status"]: { nin: ["deleted"] } };
    }

    if (index !== 1) {
      filterData["sales_orders.order_date"] = order_date_value;
    }
    if (isMyOrder) {
      filterData["employee"] = employee;
    }

    if (POCreatorPermission() && customer_index.length > 0 && customer_index[0] !== "0") {
      filterData["sales_orders.farm_code"] = customer;
    }

    if (!filterDataRef.current.isAnyPrice && filterDataRef.current.price) {
      filterData["total_price"] = [filterDataRef.current.price.min, filterDataRef.current.price.max];
    }

    if (employees_index) {
      if (employees_index[0] !== "0" && employees_index.length > 0) {
        filterData["sales_orders.owner"] = employees;
      }
    }

    let currentPage = pageRef.current;

    if (showOrder) {
      const params_filter = [
        "employee.id",
        "farm_code.id",
        "farm_code.code",
        "farm_code.name",
        "sales_order_items.sales_order_item.quantity",
        "sales_order_items.sales_order_item.unit_price",
        "sales_order_items.sales_order_item.item_discount",
        "id",
        "status",
        "order_date",
        "order_number",
        "order_discount",
        "shipping",
        "farm_code",
      ];

      if(loadMoreStatus) {
        currentPage+=1;
      }

      var request_header = {
        fields: params_filter,
        meta: "filter_count",
        limit: limitRef.current,
        page: currentPage,
        include_archive: includeArchive
      }

      if (searchWord) {
        request_header['q'] = searchWord;
      }
      
      // get all sales_orders
      var params = Object.entries(request_header).map(e => e.join('=')).join('&');
      Orders = await directus.api.post(`/custom/sales_orders?${params}`, {
        filter: filterData
      });

      setTotalPage(Orders.meta.filter_count);

      let SortOrder = Orders.data;

      SortOrder.map((value, index) => {
        var customer_name = "";

        if (value.farm_code !== null) {
          if (value.farm_code.name) {
            customer_name = value.farm_code.name;
          }
        }

        let totalOrder = 0;
        //eslint-disable-next-line array-callback-return
        value.sales_order_items.map(data => {
          if (data.sales_order_item !== null) {
            let item_total = data.sales_order_item.unit_price * data.sales_order_item.quantity;
            if (data.sales_order_item.item_discount) {
              if (data.sales_order_item.item_discount !== '0' && data.sales_order_item.item_discount !== 0) {
                const item_total_discount = (item_total * data.sales_order_item.item_discount) / 100;
                item_total -= item_total_discount;
              }
            }
            totalOrder += item_total;
          }
        });

        if (value.order_discount > 0) {
          totalOrder -= value.order_discount;
        }
        
        if (value.shipping > 0) {
          totalOrder += value.shipping;
        }

        SortOrder[index].total_order = totalOrder;
        SortOrder[index].statusIconPath = getStatusIconPath(value.status);
      });

      const sortedData = SortOrder.reduce((acc, obj) => {
        const orderDate = new Date(obj.order_date);
        const month = orderDate.getMonth();
        const year = orderDate.getFullYear();
      
        // group by year
        let addYear = acc.find((y) => y.year === year);
        if (!addYear) {
          addYear = {
            'year': year, 
            'orderData' : []
          };
          acc.push(addYear);
        }
      
        // group by month
        let addMonth = addYear.orderData.find(m => m.month === month);
        if (!addMonth) {
          addMonth = { id: uuidv4(), month, data: [], isOpen: true };
          addYear.orderData.push(addMonth);
        }
        
        addMonth.data.push(obj);

        // sort orders by date
        addMonth.data.sort((a, b) => new Date(b.order_date) - new Date(a.order_date));

        return acc;
      }, []);
      
      sortedData.sort((a, b) => b.year - a.year); // sort by year
      sortedData.forEach(({year, orderData}) => {
        orderData.sort((a, b) => b.month - a.month); // sort by month
      });

      if (sortedData) {
        if(loadMoreStatus) {
          let currentOrders = orders;
          let newOrders = sortedData;

          newOrders.forEach((newYear) => {
            let foundYear = currentOrders.find((currentYear) => currentYear.year === newYear.year);

            if(foundYear) {
              newYear.orderData.forEach((newMonth) => {
                let foundMonth = foundYear.orderData.find((currentMonth) => currentMonth.month === newMonth.month);

                if (foundMonth) {
                  newMonth.data.forEach((newItem) => {
                    if (!foundMonth.data.find((currentItem) => currentItem.id === newItem.id)) {
                      foundMonth.data.push(newItem);
                    }
                  })
                } else {
                  foundYear.orderData.push(newMonth);
                }
              })
            } else {
              currentOrders.push(newYear);
            }
          })
          setOrders(currentOrders);
        } else {
          setOrders(sortedData);
        }
      }
    }
    
    setPage(currentPage);
    setLoadMore(false);
    setLoadingPagination(false);
    setOrdersLoading(false);
  };

  const exportOrder = async export_data => {
    let dataURL = "";

    var start_date = null;
    var end_date = null;

    var index = filterDataRef.current.date;
    if (index === 2) {
      start_date = filterDataRef.current.date_range[0];
      end_date = filterDataRef.current.date_range[1];
    } else if (index === 3) {
      start_date = moment()
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment()
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
    } else if (index === 4) {
      start_date = moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
    } else if (index === 5) {
      start_date = moment()
        .subtract(29, "days")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment().format("YYYY-MM-DD 23:59:59");
    } else if (index === 6) {
      start_date = moment()
        .subtract(2, "months")
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment()
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
    }

    //order status filter
    var order_index = filterDataRef.current.order;
    var status = null;
    if (order_index[0] !== "all") {
      status = order_index;
    }

    //customer filter
    var customer_index = filterDataRef.current.customer;
    var customer = null;

    if (customer_index[0] !== "0") {
      customer = customer_index;
    }

    //isMyOrder filter
    var isMyOrder = filterDataRef.current.isMyOrder;
    var employee = null;
    var employee_id = await getEmployee();

    if (isMyOrder) {
      employee = employee_id.id;
    }

    //employee created by filter
    var created_by_index = filterDataRef.current.employee;
    var created_by = null;
    if (created_by_index) {
      if (created_by_index[0] !== "0") {
        created_by = created_by_index;
      }
    }

    if (export_data.link === "all_detailed") {
      var file_name_temp = "temp_order_export-" + uuidv4();
      var request_body_default = {
        start_date: start_date,
        end_date: end_date,
        export_id: export_data.id,
        selected_customer: customer,
        selected_created_by: created_by,
        search_by_text: searchRef.current,
        status: status,
        employee: employee,
        isAnyPrice: filterDataRef.current.isAnyPrice,
        total_price: [filterDataRef.current.price.min, filterDataRef.current.price.max]
      };

      var end_index = 0;
      var looping_total = parseInt(totalPageRef.current / 100);
      var total_left = totalPageRef.current % 100;
      if (total_left > 0) {
        looping_total += 1;
      }
      var isLooping = true;

      if (totalPageRef.current !== 0 && totalPageRef.current !== "0") {
        for (var i = 0; i < looping_total; i++) {
          if (isLooping) {
            var request_body = request_body_default;

            var offset = 0;
            if (i === 0) {
              offset = 0;
            } else {
              offset = i * 100;
            }

            request_body["settings"] = {
              offset: offset,
              end_index: end_index,
              file_name: file_name_temp,
              isLastLooping: i === looping_total - 1
            };

            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + directus.api.auth.config.token
              },
              body: JSON.stringify(request_body)
            };

            
            dataURL = `${react_app_url}${JSON.parse(window.localStorage.getItem("company"))}/custom/export_sales_order/` + export_data.link;
            
            if (i === looping_total - 1) {
              try {
                await fetch(dataURL, requestOptions)
                  .then(resp => resp.blob())
                  .then(async blob => {
                    var file = await blob.text();
                    if (file.includes("error")) {
                      alert("Connection error. Please try again.");
                    } else if (file.includes("no_template")) {
                      alert("No Template");
                    } else {
                      const url = window.URL.createObjectURL(blob);
                      const a = document.createElement("a");
                      a.style.display = "none";
                      a.href = url;
                      // the filename you want
                      var file_name = "Order-Export";
                      if (start_date && end_date) {
                        file_name += `-${moment(start_date).format("YYYY-MM-DD")}-${moment(end_date).format("YYYY-MM-DD")}`;
                      } else {
                        file_name += "-All";
                      }
                      file_name += ".xlsx";
                      a.download = file_name;
                      document.body.appendChild(a);
                      a.click();
                      window.URL.revokeObjectURL(url);
                    }
                  });

                EventEmitter.emit("export_loading", { value: false });
              } catch (error) {
                alert("Connection error. Please try again.");
                EventEmitter.emit("export_loading", { value: false });
              }
            } else {
              try {
                await fetch(dataURL, requestOptions)
                  .then(resp => resp.json())
                  .then(resp => {
                    if (resp.isLooping) {
                      end_index = resp.end_index + 1;
                    } else {
                      isLooping = false;
                      EventEmitter.emit("export_loading", { value: false });
                      alert("Connection error. Please try again.");
                    }
                  });
              } catch (error) {
                alert("Connection error. Please try again.");
                EventEmitter.emit("export_loading", { value: false });
              }
            }
          }
        }
      } else {
        EventEmitter.emit("export_loading", { value: false });
      }
    } else {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + directus.api.auth.config.token },
        body: JSON.stringify({
          start_date: start_date,
          end_date: end_date,
          export_id: export_data.id,
          selected_customer: customer,
          selected_created_by: created_by,
          search_by_text: searchRef.current,
          status: status,
          employee: employee,
          isAnyPrice: filterDataRef.current.isAnyPrice,
          total_price: [filterDataRef.current.price.min, filterDataRef.current.price.max]
        })
      };

      dataURL = `${react_app_url}${JSON.parse(window.localStorage.getItem("company"))}/custom/export_sales_order/` + export_data.link;

      try {
        await fetch(dataURL, requestOptions)
          .then(resp => resp.blob())
          .then(async blob => {
            var file = await blob.text();
            if (file.includes("error")) {
              alert("Connection error. Please try again.");
            } else if (file.includes("no_template")) {
              alert("No Template");
            } else {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = url;
              // the filename you want
              var file_name = "Order-Export";
              if (start_date && end_date) {
                file_name += `-${moment(start_date).format("YYYY-MM-DD")}-${moment(end_date).format("YYYY-MM-DD")}`;
              } else {
                file_name += "-All";
              }
              file_name += ".xlsx";
              a.download = file_name;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
            }
          });
      } catch (error) {
        alert("Connection error. Please try again.");
        EventEmitter.emit("export_loading", { value: false });
      }
      EventEmitter.emit("export_loading", { value: false });
    }
    EventEmitter.emit("export_loading", { value: false });
  };

  const formatMonth = month => {
    switch (month) {
      case 0:
        return 'January';
      case 1:
        return 'February';
      case 2:
        return 'March';
      case 3:
        return 'April';
      case 4:
        return 'May';
      case 5:
        return 'June';
      case 6:
        return 'July';
      case 7:
        return 'August';
      case 8:
        return 'September';
      case 9:
        return 'October';
      case 10:
        return 'November';
      case 11:
        return 'December';
      default:
        return '';
    }
  }

  const formatNumber = num => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  const findNestedObj = (entireObj, keyToFind) => {
    //eslint-disable-next-line no-unused-vars
    let foundObj;
    var list = [];
    JSON.stringify(entireObj, (_, nestedValue) => {
      if (nestedValue) {
        if (nestedValue && nestedValue[keyToFind]) {
          if (list.indexOf(nestedValue[keyToFind]) === -1) {
            foundObj = nestedValue;
            list.push(nestedValue[keyToFind]);
          }
        }
      }
      return nestedValue;
    });
    return list;
  };

  var findNestedObjByKey = (theObject, keyToFind) => {
    var result = [];
    if (theObject instanceof Array) {
      for (let index = 0; index < theObject.length; index++) {
        const obj = theObject[index];

        for (const [key, value] of Object.entries(obj)) {
          if (value && key === keyToFind && result.indexOf(value) === -1) {
            result.push(value);
          } else if (value instanceof Object || value instanceof Array) {
            result = result.concat(findNestedObjByKey(value, keyToFind));
          }
        }
      }
    } else if (theObject instanceof Object) {
      for (const [key, value] of Object.entries(theObject)) {
        if (value && key === keyToFind && result.indexOf(value) === -1) {
          result.push(value);
        } else if (value instanceof Object || value instanceof Array) {
          result = result.concat(findNestedObjByKey(value, keyToFind));
        }
      }
    }

    return result;
  };

  const handleClickOrder = (e, orderId) => {
    e.stopPropagation();
    navigate(`/sales-orders/${orderId}`);
    setSelectedOrderId(orderId);
    EventEmitter.emit("selected_sales_order");
  }

  const handleClickGroupingOrders = (groupOrderId) => {
    setOrders(prevOrders => prevOrders.map(orderData => ({
      ...orderData,
      orderData: orderData.orderData.map(item =>
        item.id === groupOrderId
          ? { ...item, isOpen: !item.isOpen }
          : item
      )
    })));
  };

  const handleClickLoadMore = () => {
    setLoadMore(!loadMore);
    loadOrders('', true);
  }

  const noDataFound = () => (
    <Grid container direction="column" justifyContent="center" alignItems="center" style={{ opacity: "0.2", paddingTop: "2pc" }}>
      <Grid item xs={12} sm={12} lg={12}>
        <img alt="No Data Found" height="200" width="200" src="/static/images/folder.png?v=0.0.1" />
        <Typography variant="h3" style={{ textAlign: "end" }}>
          No Data Found
        </Typography>
      </Grid>
    </Grid>
  )

  return (
    <div style={{ paddingTop: '19px', paddingBottom: '99px' }}>
      {!ordersLoading ?
        orders ?
          orders.length > 0 ?
            orders.map((list, index) => {
              const year = list.year;
              const orderData = list.orderData;

              return (
                <div key={`order-year-group-${index}`}>
                  {orderData && orderData.length > 0 && orderData.map((item, index) => {
                    return (
                      <div key={`order-month-group-${index}`} onClick={() => handleClickGroupingOrders(item.id)}>
                        <div className='month-header-wrapper' style={{ display: 'flex', marginBottom: '3px', cursor: 'pointer' }}>
                          <Typography noWrap component="div" className={classes.headerStyle}>
                            <OverflowTip>{`${formatMonth(item.month)} ${year}`}</OverflowTip>
                          </Typography>
                          <div className={classes.arrowDownWrapper}>
                            {item.isOpen ? 
                              <KeyboardArrowDownIcon style={{ color: 'rgba(0, 122, 255, 1)' }} />
                            :
                              <KeyboardArrowRightIcon style={{ color: 'black' }} />
                            }
                          </div>
                        </div>

                        <div style={{ display: item.isOpen ? 'block' : 'none' }}>
                          {item.data && item.data.length > 0 && item.data.map((order, index) => {
                            const isHighlight = order.id == selectedOrderIdRef.current;
                            const isLastIndex = index === item.data.length - 1;

                            return (
                              <div
                                key={`order-list-${index}`}
                                className='order-list-wrapper'
                                style={{ borderRadius: '10px', backgroundColor: 'white', border: isHighlight ? '2px solid #82CB43' : 'none', cursor: 'pointer', marginBottom: isLastIndex ? '25px' : '10px' }}
                                onClick={(e) => handleClickOrder(e, order.id)}
                              >   
                                <Grid container spacing={0} justifyContent="space-around" className={classes.gridContainer}>
                                  <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridItem}>
                                    <img src='/static/images/icons/so-icon.svg' style={{  fontSize: '17px' }} />
                                  </Grid>
                                  <Grid item xs={7} sm={7} md={7} lg={7} className={classes.gridItem} style={{ paddingLeft: '10px' }}>
                                    <Typography component="div" className={classes.typographyStyle3}>
                                      <OverflowTip>
                                        {order.status === 'draft' ? 'DRAFT' : `S${order.order_number}`}
                                      </OverflowTip>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                                    <Typography
                                      component="div"
                                      className={classes.typographyStyle2}
                                      style={{ color: 'rgba(60, 60, 67, 0.6)', fontSize: '15px' }}
                                    >
                                      <OverflowTip>{moment(order.order_date).format('DD/MM/YYYY')}</OverflowTip>
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <div style={{ backgroundColor: 'white' }}>
                                  <Divider style={{ marginLeft: '49px'}} />
                                </div>
                                <Grid container spacing={0} justifyContent="space-around" className={classes.gridContainer}>
                                  <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridItem}>
                                  </Grid>
                                  <Grid item xs={9} sm={9} md={9} lg={9} className={classes.gridItem} style={{ paddingLeft: '10px' }}>
                                    <Typography
                                      component="div"
                                      className={classes.typographyStyle2}
                                      style={{ color: 'rgba(60, 60, 67, 0.6)', fontSize: '15px' }}
                                    >
                                      <OverflowTip>Account: {order.farm_code ? order.farm_code.name ?? '' : ''}</OverflowTip>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                                    <KeyboardArrowRightIcon style={{ color: 'rgba(60, 60, 67, 0.3)'}} />
                                  </Grid>
                                </Grid>
                                <div style={{ backgroundColor: 'white' }}>
                                  <Divider style={{ marginLeft: '49px'}} />
                                </div>
                                <Grid container spacing={0} justifyContent="space-around" className={classes.gridContainer}>
                                  <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridItem}>
                                    <img src={order.statusIconPath} />
                                  </Grid>
                                  <Grid item xs={7} sm={7} md={7} lg={7} className={classes.gridItem} style={{ paddingLeft: '10px' }}>
                                    <Typography
                                      component="div"
                                      className={classes.typographyStyle3}
                                      style={{ fontSize: '15px', textTransform: 'capitalize' }}
                                    >
                                      <OverflowTip>{order.status === 'awaiting_approval' ? 'Awaiting Approval' : order.status}</OverflowTip>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                                    <Typography
                                      component="div"
                                      className={classes.typographyStyle2}
                                      style={{ color: 'rgba(60, 60, 67, 0.6)', fontSize: '15px' }}
                                    >
                                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <OverflowTip>{isNaN(order.total_order) ? 0 : '$' + formatNumber(order.total_order.toFixed(2))}</OverflowTip>
                                      </div>
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </div>
                            )
                          })}
                        </div>

                      </div>
                    )
                  })}
                </div>
              )
              
            })
          : noDataFound()
        : noDataFound()
      : (
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="rounded" height={148} style={{ borderRadius: '10px', marginBottom: '10px' }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="rounded" height={148} style={{ borderRadius: '10px', marginBottom: '10px' }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="rounded" height={148} style={{ borderRadius: '10px', marginBottom: '10px' }} />
          </Grid>
        </Grid>
      )}
      {!ordersLoading && orders && orders.length > 0 ?
        pageRef.current < Math.ceil(totalPage / limitRef.current) ?
          <LoadingButtonCustom
            loading={loadMore}
            loadingPosition="end"
            endIcon={<AddIcon />}
            variant="outlined"
            onClick={() => handleClickLoadMore()}
          >
            <span style={{ textTransform: 'capitalize', fontFamily: 'SF Pro' }}>Load More</span>
          </LoadingButtonCustom>
        :''
      :''
      }
    </div>
  )
}

export default AllOrdersListView