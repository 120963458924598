import React, { useState, useEffect } from "react";
import Page from "src/components/Page";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Container,
  CircularProgress,
  Grid,
  Button,
  MenuItem,
  TextField,
  withStyles,
  LinearProgress,
} from "@material-ui/core";
import './style.css';
import { enableRipple } from "@syncfusion/ej2-base";
import directus from "../../services/directus";
import { useStyles } from "./style.js";
import useStateRef from "react-usestateref";
import EventEmitter from "src/utils/EventEmitter";
import linq from "linq";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import { pageOptions } from "src/_utils/pageOptions";
import { getCache, setCache } from "src/utils/KeyValueCache";

import moment from "moment";
import { POApproverPermission, POCreatorPermission } from "src/utils/Permission";
import { getEmployee } from "src/utils/sessions";
import SortRoundedIcon from "@material-ui/icons/SortRounded";
import { OverflowTip } from "src/components/OverflowTip";
import { v4 as uuidv4 } from "uuid";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

export const SearchButton = withStyles({
  root: {
    marginRight: "22px",
    borderRadius: "24px",
    backgroundColor: "#2AC940",
    color: "#ffffff",
    height: "25px",
    width: "100px",
    minWidth: "50px",
    "&:hover": {
      backgroundColor: "#22a033"
    }
  }
})(Button);

let page = 1;

var react_app_url = process.env.REACT_APP_URL;
if (window.location.hostname.search("interax") !== -1) {
  react_app_url = process.env.REACT_APP_URL_INTERAX;
}

const headerData = [
  { id: "icon", name: "", width_table: 4 },
  { id: "order_number", name: "Order No.", width_table: 10 },
  { id: "date", name: "Date", width_table: 12 },
  { id: "supplier", name: "Supplier", width_table: 23 },
  { id: "customer", name: "Account", width_table: 23 },
  { id: "amount", name: "Amount", width_table: 12 },
  { id: "status", name: "Status", width_table: 16 },
]

const headerDataWithNoAccount = [
  { id: "icon", name: "", width_table: 4 },
  { id: "order_number", name: "Order No.", width_table: 10 },
  { id: "date", name: "Date", width_table: 15 },
  { id: "supplier", name: "Supplier", width_table: 33 },
  { id: "amount", name: "Amount", width_table: 22 },
  { id: "status", name: "Status", width_table: 16 },
]

const AllPurchaseOrders = () => {
  /* eslint-disable no-unused-vars*/
  const navigate = useNavigate();
  const classes = useStyles();
  const [initialized, setInitialized] = useState(false);
  const [orders, setOrders, ordersRef] = useStateRef(null);
  const [search, setSearch, searchRef] = useStateRef("");
  const [showPOAccount, setShowPOAccount] = useState(false);
  const [totalPage, setTotalPage, totalPageRef] = useStateRef("");
  const [pageNumber, setPageNumber, pageNumberRef] = useStateRef(100);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [orderEventType, setOrderEventType] = useState(null);
  const [sortLoading, setSortLoading, sortLoadingRef] = useStateRef(false);
  const [filterLoading, setFilterLoading, filterLoadingRef] = useStateRef(false);
  const [filterData, setFilterData, filterDataRef] = useStateRef({
    date: 6,
    date_range: [],
    order: ["all"],
    invoice: ["all"],
    supplier: ["0"],
    customer: ["0"],
    employee: ["0"],
    po_category: ["0"],
    isMyOrder: POApproverPermission() ? false : true,
    isAnyPrice: true,
    price: { min: 0, max: 0 }
  });


  const [sortField, setSortField, sortFieldRef] = useStateRef({
    order_number: { type: "desc", value: false },
    date: { type: "desc", value: false },
    supplier: { type: "desc", value: false },
    customer: { type: "desc", value: false },
    status: { type: "desc", value: false },
    amount: { type: "desc", value: false },
    match_invoice: { type: "desc", value: false }
  });

  /* eslint-enable no-unused-vars*/

  enableRipple(true);

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      page = 1;
      getConfigurationSettings();
      setInitialized(true);
      loadFirst();
      loadOrderEventType();
    }

    const onSearch = async eventData => {
      setSearch(eventData.text);
      page = 1;
      await loadOrders(eventData.text);
      EventEmitter.emit("searching_loading", {
        value: false
      });
    };
    const listener = EventEmitter.addListener(window.location.pathname, onSearch);

    const onExportOrder = eventData => {
      exportOrder(eventData.text);
    };
    const listener2 = EventEmitter.addListener("export", onExportOrder);

    const add = eventData => {
      navigate(`/purchase-orders/new`);
    };
    const listener3 = EventEmitter.addListener("addOrder", add);

    return () => {
      listener.remove();
      listener2.remove();
      listener3.remove();
    };
  }, []);

  useEffect(() => {
    const onFilterData = async eventData => {
      setFilterData(eventData);
      setFilterLoading(true);
      page = 1;
      await loadOrders();
      EventEmitter.emit("searching_loading", {
        value: false
      });
    };
    const listener1 = EventEmitter.addListener("filter_data", onFilterData);

    return () => {
      listener1.remove();
    };
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps*/

  const loadFirst = () => {
    var filter_data = localStorage.filter_data;
    if (filter_data) {
      filter_data = JSON.parse(filter_data);
      if (!filter_data.price) {
        filter_data.price = {
          min: 0,
          max: 0
        };
        localStorage.setItem("filter_data", JSON.stringify(filter_data));
      }

      if (!filter_data.employee) {
        filter_data.employee = ["0"];
      }

      if (!filter_data.po_category) {
        filter_data.po_category = ["0"];
      }

      setFilterData(filter_data);
    }

    var sort_data = localStorage.sort_data;
    if (sort_data) {
      sort_data = JSON.parse(sort_data);
      if (sort_data.order) {
        var sort_data_order = sort_data.order;
        if (!sort_data_order.customer && showPOAccount) {
          sort_data_order.customer = { type: "desc", value: false };
        }
        if (!sort_data_order.date) {
          sort_data_order.date = { type: "desc", value: false };
        }
        if (!sort_data_order.order_number) {
          sort_data_order.order_number = { type: "desc", value: false };
        }
        if (!sort_data_order.status) {
          sort_data_order.status = { type: "desc", value: false };
        }
        if (!sort_data_order.supplier) {
          sort_data_order.supplier = { type: "desc", value: false };
        }
        if (!sort_data_order.amount) {
          sort_data_order.amount = { type: "desc", value: false };
        }
        if (!sort_data_order.match_invoice) {
          sort_data_order.match_invoice = { type: "desc", value: false };
        }
        setSortField(sort_data_order);
      }
    }
    loadOrders();
  };

  const loadOrders = async (data, firstLoad = false) => {
    setOrdersLoading(true);

    //------------------ sort -------------- //
    var customer_sort = sortFieldRef.current.customer;
    var date_sort = sortFieldRef.current.date;
    var order_number_sort = sortFieldRef.current.order_number;
    var status_sort = sortFieldRef.current.status;
    var supplier_sort = sortFieldRef.current.supplier;
    var amount_sort = sortFieldRef.current.amount;
    var matched_invoice_sort = sortFieldRef.current.match_invoice;

    var orderByFieldCustom = `-orders.order_date`;

    if (order_number_sort.value) {
      if (order_number_sort.type === "asc") {
        orderByFieldCustom = `orders.order_number`;
      } else if (order_number_sort.type === "desc") {
        orderByFieldCustom = `-orders.order_number`;
      }
    }

    if (date_sort.value) {
      if (date_sort.type === "asc") {
        orderByFieldCustom = `orders.order_date`;
      } else if (date_sort.type === "desc") {
        orderByFieldCustom = `-orders.order_date`;
      }
    }

    if (supplier_sort.value) {
      if (supplier_sort.type === "asc") {
        orderByFieldCustom = "suppliers.name";
      } else if (supplier_sort.type === "desc") {
        orderByFieldCustom = "-suppliers.name";
      }
    }

    if (showPOAccount && customer_sort.value) {
      if (customer_sort.type === "asc") {
        orderByFieldCustom = "customers.name";
      } else if (customer_sort.type === "desc") {
        orderByFieldCustom = "-customers.name";
      }
    }

    if (status_sort.value) {
      if (status_sort.type === "asc") {
        orderByFieldCustom = `orders.status`;
      } else if (status_sort.type === "desc") {
        orderByFieldCustom = `-orders.status`;
      }
    }

    if (amount_sort.value) {
      if (amount_sort.type === "asc") {
        orderByFieldCustom = "amount";
      } else if (amount_sort.type === "desc") {
        orderByFieldCustom = "-amount";
      }
    }

    if (matched_invoice_sort.value) {
      if (matched_invoice_sort.type === "asc") {
        orderByFieldCustom = "matched_invoice";
      } else if (matched_invoice_sort.type === "desc") {
        orderByFieldCustom = "-matched_invoice";
      }
    }

    //------------------ filter -------------- //
    let filterData = {};
    // var employee = await getEmployee();

    if (searchRef.current) {
      data = searchRef.current;
    }
    var Orders = null;
    var order_date_value = { nnull: null };

    //date filter
    var index = filterDataRef.current.date;
    var start_date = moment().format("YYYY-MM-DD 00:00:00");
    var end_date = moment().format("YYYY-MM-DD 23:59:59");
    if (index === 2) {
      start_date = filterDataRef.current.date_range[0];
      end_date = filterDataRef.current.date_range[1];
    } else if (index === 3) {
      start_date = moment()
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment()
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
    } else if (index === 4) {
      start_date = moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
    } else if (index === 5) {
      start_date = moment()
        .subtract(29, "days")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment().format("YYYY-MM-DD 23:59:59");
    } else if (index === 6) {
      start_date = moment()
        .subtract(2, "months")
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment()
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
    }

    if (index !== 1) {
      order_date_value = { between: [start_date, end_date] };
    }

    //order status filter
    var order_index = filterDataRef.current.order;
    var status = { nnull: null };
    if (order_index[0] !== "all") {
      status = { in: order_index };
    }

    //invoice status filter
    var invoice_index = filterDataRef.current.invoice;
    var invoice_status = null;
    if (invoice_index[0] !== "all") {
      invoice_status = { in: invoice_index };
    }

    //supplier filter
    var supplier_index = filterDataRef.current.supplier;
    var supplier = { nnull: null };
    if (supplier_index[0] !== "0") {
      supplier = { in: supplier_index };
    }

    var employee_id = await getEmployee();
    //customer filter
    var customer_index = filterDataRef.current.customer;
    var customer = { nnull: null };
    var showOrder = false;

    //matched with invoice filter
    var matched_invoice_index = filterDataRef.current.matched_invoice;
    var matched_invoice = "";
    if (matched_invoice_index) {
      if (matched_invoice_index[0] !== "all" && matched_invoice_index.length === 1) {
        matched_invoice = matched_invoice_index[0];
      }
    }

    //employee filter
    var employees_index = filterDataRef.current.employee;
    var employees = { nnull: null };
    if (employees_index[0] !== "0") {
      employees = { in: employees_index };
    }


    //po category filter
    var po_category_index = filterDataRef.current.po_category;
    var po_categories = { nnull: null };

    if (po_category_index[0] !== "0") {
      po_categories = { in: po_category_index };
    }

    if (POCreatorPermission()) {
      showOrder = true;
    }

    if (customer_index[0] !== "0") {
      customer = { in: customer_index };
    }
    
    var isMyOrder = filterDataRef.current.isMyOrder;
    var includeArchive = filterDataRef.current.includeArchivedOrders;
    
    var employee = { nnull: null };

    if (isMyOrder) {
      employee = { eq: employee_id.id };
    }

    if (order_index[0] !== "all") {
      filterData[`orders.status`] = status;
    } else {
      filterData = { [`orders.status`]: { nin: ["deleted"] } };
    }

    if (index !== 1) {
      filterData[`orders.order_date`] = order_date_value;
    }
    if (isMyOrder) {
      filterData["employee"] = employee;
    }
    if (supplier_index[0] !== "0" && supplier_index.length > 0) {
      filterData[`orders.supplier`] = supplier;
    }

    if (POCreatorPermission() && customer_index.length > 0 && customer_index[0] !== "0") {
      filterData[`orders.farm_code`] = customer;
    }

    if (invoice_status) {
      filterData["invoices.invoice_status"] = invoice_status;
      filterData["invoices.status"] = "published";
    }

    if (matched_invoice === "matched") {
      filterData["matched_with_invoice"] = "matched";
    } else if (matched_invoice === "unmatched") {
      filterData["matched_with_invoice"] = "unmatched";
    }

    if (!filterDataRef.current.isAnyPrice && filterDataRef.current.price) {
      filterData["total_price"] = [filterDataRef.current.price.min, filterDataRef.current.price.max];
    }

    if (employees_index) {
      if (employees_index[0] !== "0" && employees_index.length > 0) {
        filterData[`orders.owner`] = employees;
      }
    }

    if (po_category_index) {
      if (po_category_index[0] !== "0" && po_category_index.length > 0) {
        filterData[`orders.category_type`] = po_categories;
      }
    }

    if (filterDataRef.current.po_category_description) {
      filterData["category_type_description"] = filterDataRef.current.po_category_description;
    }

    if (showOrder) {
      var params_filter = [
        "employee.id",
        "employee.owner",
        "supplier.id",
        "supplier.name",
        "supplier.status",
        "farm_code.id",
        "farm_code.name",
        "farm_code.legal_name",
        "category_type.*",
        "invoices.*",
        "invoices.orders_id.id",
        "invoices.invoices_id.orders.orders_id.id",
        "invoices.invoices_id.orders.orders_id.order_number",
        "invoices.invoices_id.orders.orders_id.order_items.order_item.id",
        "invoices.invoices_id.orders.orders_id.order_items.order_item.unit_price",
        "invoices.invoices_id.orders.orders_id.order_items.order_item.quantity",
        "invoices.invoices_id.*",
        "order_items.order_item.id",
        "order_items.order_item.quantity",
        "order_items.order_item.unit_price",
        "order_items.id",
        "id",
        "status",
        "order_date",
        "supplier",
        "order_number",
        "farm_code",
        "category_type",
      ];

      var request_header = {
        fields: params_filter,
        meta: "filter_count",
        limit: pageNumberRef.current,
        page: page,
        sort: orderByFieldCustom,
        include_archive: includeArchive
      }

      if (data) {
        request_header['q'] = data;
      }

      var params = Object.entries(request_header).map(e => e.join('=')).join('&');
      Orders = await directus.api.post(`/custom/orders?${params}`, {
        filter: filterData
      });

      setOrders(Orders.data);
      setTotalPage(Orders.meta.filter_count);

      let SortOrder;
      let sort_type = "";
      let sort_by_data = "";
      let changeUpperCase = false;

      if (supplier_sort.value) {
        sort_by_data = "supplier_name";
        sort_type = supplier_sort.type;
        changeUpperCase = true;
      } else if (customer_sort.value) {
        sort_by_data = "customer_name";
        sort_type = customer_sort.type;
        changeUpperCase = true;
      } else if (date_sort.value) {
        sort_by_data = "order_date";
        sort_type = date_sort.type;
      } else if (order_number_sort.value) {
        sort_by_data = "order_number";
        sort_type = order_number_sort.type;
      } else if (status_sort.value) {
        sort_by_data = "status";
        sort_type = status_sort.type;
      } else if (amount_sort.value) {
        sort_by_data = "total_order";
        sort_type = amount_sort.type;
      } else if (matched_invoice_sort.value) {
        sort_by_data = "matched_invoice_flag";
        sort_type = matched_invoice_sort.type;
      } else {
        sort_by_data = "order_date";
        sort_type = "desc";
      }

      SortOrder = Orders.data;

      SortOrder.map((value, index) => {
        var supplier_name = "";
        var customer_name = "";
        var matched_invoice_flag = false;

        if (value.supplier !== null) {
          if (value.supplier.name) {
            supplier_name = value.supplier.name;
          }
        }

        if (value.farm_code !== null) {
          if (value.farm_code.name) {
            customer_name = value.farm_code.name;
          }
        }

        SortOrder[index].supplier_name = supplier_name;
        SortOrder[index].customer_name = customer_name;

        let totalOrder = 0;
        //eslint-disable-next-line array-callback-return
        value.order_items.map(data => {
          if (data.order_item !== null) {
            totalOrder = totalOrder + data.order_item.unit_price * data.order_item.quantity;
          }
        });

        if (value.invoices.length > 0) {
          matched_invoice_flag = true;
        }
        SortOrder[index].total_order = totalOrder;
        SortOrder[index].matched_invoice_flag = matched_invoice_flag;
      });

      SortOrder = linq
        .from(SortOrder)
        .orderByDescending(o => o.date)
        .toArray();

      if (sort_by_data) {
        if (sort_type === "desc") {
          SortOrder.sort(function (a, b) {
            let nameA = a[sort_by_data] ? a[sort_by_data] : "";
            let nameB = b[sort_by_data] ? b[sort_by_data] : "";
            if (changeUpperCase) {
              nameA = nameA ? nameA.toUpperCase() : nameA; // ignore upper and lowercase
              nameB = nameB ? nameB.toUpperCase() : nameB; // ignore upper and lowercase
            }

            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            return 0; // names must be equal
          });
        } else {
          SortOrder.sort(function (a, b) {
            let nameA = a[sort_by_data] ? a[sort_by_data] : "";
            let nameB = b[sort_by_data] ? b[sort_by_data] : "";
            if (changeUpperCase) {
              nameA = nameA ? nameA.toUpperCase() : nameA; // ignore upper and lowercase
              nameB = nameB ? nameB.toUpperCase() : nameB; // ignore upper and lowercase
            }

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0; // names must be equal
          });
        }
      }

      if (SortOrder) {
        setOrders(SortOrder);
      }
    }
    setSortLoading(false);
    setFilterLoading(false);
    setLoadingPagination(false);
    setOrdersLoading(false);
  };

  const loadOrderEventType = async () => {
    let company = JSON.parse(window.localStorage.getItem("company"));
    let token = JSON.parse(window.localStorage.getItem("directus_data"));

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.token
      }
    };

    var order_event_type = getCache("order_event_type_options_choices");

    if (!order_event_type) {
      fetch(`${react_app_url}${company}/fields/order_event/type`, requestOptions)
        .then(response => response.json())
        .then(async result => {
          if (result.data) {
            setOrderEventType(result.data.options.choices);
            setCache("order_event_type_options_choices", result.data.options.choices);
          }
        });
    } else {
      setOrderEventType(order_event_type);
    }
  };

  const getConfigurationSettings = async () => {
    var result = await directus.getItems('configuration');
    window.localStorage.setItem('configuration', JSON.stringify(result.data[0]));
    if (result.data.length > 0) {
      if (result.data[0]) {
        if (result.data[0].show_po_account === true) {
          setShowPOAccount(true);
        }
      }
    }
  }

  const formatNumber = num => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  const pageChange = value => {
    setLoadingPagination(true);
    page = page + value;
    loadOrders(searchRef.current);
  };

  const handleChange = event => {
    setPageNumber(event.target.value);
    page = 1;
    setLoadingPagination(true);
    loadOrders(searchRef.current);
  };

  const exportOrder = async export_data => {
    let dataURL = "";

    var start_date = null;
    var end_date = null;

    var index = filterDataRef.current.date;
    if (index === 2) {
      start_date = filterDataRef.current.date_range[0];
      end_date = filterDataRef.current.date_range[1];
    } else if (index === 3) {
      start_date = moment()
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment()
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
    } else if (index === 4) {
      start_date = moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
    } else if (index === 5) {
      start_date = moment()
        .subtract(29, "days")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment().format("YYYY-MM-DD 23:59:59");
    } else if (index === 6) {
      start_date = moment()
        .subtract(2, "months")
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment()
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
    }

    //order status filter
    var order_index = filterDataRef.current.order;
    var status = null;
    if (order_index[0] !== "all") {
      status = order_index;
    }

    //invoice status filter
    var invoice_index = filterDataRef.current.invoice;
    var invoice_status = null;
    if (invoice_index[0] !== "all") {
      invoice_status = invoice_index;
    }

    //customer filter
    var customer_index = filterDataRef.current.customer;
    var customer = null;

    // if (!POApproverPermission()) {
    //   customer = customerEmployeeRef.current;
    // }
    if (customer_index[0] !== "0") {
      customer = customer_index;
    }

    //supplier filter
    var supplier_index = filterDataRef.current.supplier;
    var supplier = null;
    if (supplier_index[0] !== "0") {
      supplier = supplier_index;
    }

    //isMyOrder filter
    var isMyOrder = filterDataRef.current.isMyOrder;
    // if (!POApproverPermission()) {
    //   isMyOrder = true;
    // }
    var employee = null;
    var employee_id = await getEmployee();

    if (isMyOrder) {
      employee = employee_id.id;
    }

    //matched invoice filter
    var matched_invoice_index = filterDataRef.current.matched_invoice;
    var matched_invoice = null;
    if (matched_invoice_index) {
      if (matched_invoice_index[0] !== "all" && matched_invoice_index.length === 1) {
        matched_invoice = matched_invoice_index[0];
      }
    }

    //employee created by filter
    var created_by_index = filterDataRef.current.employee;
    var created_by = null;
    if (created_by_index) {
      if (created_by_index[0] !== "0") {
        created_by = created_by_index;
      }
    }

    //po category filter
    var po_category_index = filterDataRef.current.po_category;
    var po_category = null;
    if (po_category_index) {
      if (po_category_index[0] !== "0") {
        po_category = po_category_index;
      }
    }

    //------------------ sort -------------- //
    var customer_sort = sortFieldRef.current.customer;
    var date_sort = sortFieldRef.current.date;
    var order_number_sort = sortFieldRef.current.order_number;
    var status_sort = sortFieldRef.current.status;
    var supplier_sort = sortFieldRef.current.supplier;
    var amount_sort = sortFieldRef.current.amount;
    var matched_invoice_sort = sortFieldRef.current.match_invoice;

    let sort_type = "";
    let sort_by_data = "";

    if (supplier_sort.value) {
      sort_by_data = "supplier_name";
      sort_type = supplier_sort.type;
    } else if (customer_sort.value) {
      sort_by_data = "customer_name";
      sort_type = customer_sort.type;
    } else if (date_sort.value) {
      sort_by_data = "order_date";
      sort_type = date_sort.type;
    } else if (order_number_sort.value) {
      sort_by_data = "order_number";
      sort_type = order_number_sort.type;
    } else if (status_sort.value) {
      sort_by_data = "status";
      sort_type = status_sort.type;
    } else if (amount_sort.value) {
      sort_by_data = "amount";
      sort_type = amount_sort.type;
    } else if (matched_invoice_sort.value) {
      sort_by_data = "matched_invoice_flag";
      sort_type = matched_invoice_sort.type;
    } else {
      sort_by_data = "order_date";
      sort_type = "desc";
    }

    if (export_data.link === "all_detailed" || export_data.link === "all_invoice_qty_detailed") {
      var file_name_temp = "temp_order_export-" + uuidv4();
      var request_body_default = {
        start_date: start_date,
        end_date: end_date,
        export_id: export_data.id,
        selected_customer: customer,
        selected_supplier: supplier,
        selected_created_by: created_by,
        selected_po_category: po_category,
        search_by_text: searchRef.current,
        status: status,
        invoice_status: invoice_status,
        employee: employee,
        matched_invoice: matched_invoice,
        custom_sort: { sort_by_data: sort_by_data, sort_type: sort_type },
        isAnyPrice: filterDataRef.current.isAnyPrice,
        total_price: [filterDataRef.current.price.min, filterDataRef.current.price.max]
      };

      var end_index = 0;
      var looping_total = parseInt(totalPageRef.current / 100);
      var total_left = totalPageRef.current % 100;
      if (total_left > 0) {
        looping_total += 1;
      }
      var isLooping = true;

      if (totalPageRef.current !== 0 && totalPageRef.current !== "0") {
        for (var i = 0; i < looping_total; i++) {
          if (isLooping) {
            var request_body = request_body_default;

            var offset = 0;
            if (i === 0) {
              offset = 0;
            } else {
              offset = i * 100;
            }

            request_body["settings"] = {
              offset: offset,
              end_index: end_index,
              file_name: file_name_temp,
              isLastLooping: i === looping_total - 1
            };

            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + directus.api.auth.config.token
              },
              body: JSON.stringify(request_body)
            };

            dataURL = `${react_app_url}${JSON.parse(window.localStorage.getItem("company"))}/custom/export_order/` + export_data.link;

            if (i === looping_total - 1) {
              try {
                await fetch(dataURL, requestOptions)
                  .then(resp => resp.blob())
                  .then(async blob => {
                    var file = await blob.text();
                    if (file.includes("error")) {
                      alert("Connection error. Please try again.");
                    } else if (file.includes("no_template")) {
                      alert("No Template");
                    } else {
                      const url = window.URL.createObjectURL(blob);
                      const a = document.createElement("a");
                      a.style.display = "none";
                      a.href = url;
                      // the filename you want
                      var file_name = "Order-Export";
                      if (start_date && end_date) {
                        file_name += `-${moment(start_date).format("YYYY-MM-DD")}-${moment(end_date).format("YYYY-MM-DD")}`;
                      } else {
                        file_name += "-All";
                      }
                      file_name += ".xlsx";
                      a.download = file_name;
                      document.body.appendChild(a);
                      a.click();
                      window.URL.revokeObjectURL(url);
                    }
                  });

                EventEmitter.emit("export_loading", { value: false });
              } catch (error) {
                alert("Connection error. Please try again.");
                EventEmitter.emit("export_loading", { value: false });
              }
            } else {
              try {
                await fetch(dataURL, requestOptions)
                  .then(resp => resp.json())
                  .then(resp => {
                    if (resp.isLooping) {
                      end_index = resp.end_index + 1;
                    } else {
                      isLooping = false;
                      EventEmitter.emit("export_loading", { value: false });
                      alert("Connection error. Please try again.");
                    }
                  });
              } catch (error) {
                alert("Connection error. Please try again.");
                EventEmitter.emit("export_loading", { value: false });
              }
            }
          }
        }
      } else {
        EventEmitter.emit("export_loading", { value: false });
      }
    } else {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + directus.api.auth.config.token },
        body: JSON.stringify({
          start_date: start_date,
          end_date: end_date,
          export_id: export_data.id,
          selected_customer: customer,
          selected_supplier: supplier,
          selected_created_by: created_by,
          selected_po_category: po_category,
          search_by_text: searchRef.current,
          status: status,
          invoice_status: invoice_status,
          employee: employee,
          matched_invoice: matched_invoice,
          custom_sort: { sort_by_data: sort_by_data, sort_type: sort_type },
          isAnyPrice: filterDataRef.current.isAnyPrice,
          total_price: [filterDataRef.current.price.min, filterDataRef.current.price.max]
        })
      };

      dataURL = `${react_app_url}${JSON.parse(window.localStorage.getItem("company"))}/custom/export_order/` + export_data.link;

      try {
        await fetch(dataURL, requestOptions)
          .then(resp => resp.blob())
          .then(async blob => {
            var file = await blob.text();
            if (file.includes("error")) {
              alert("Connection error. Please try again.");
            } else if (file.includes("no_template")) {
              alert("No Template");
            } else {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = url;
              // the filename you want
              var file_name = "Order-Export";
              if (start_date && end_date) {
                file_name += `-${moment(start_date).format("YYYY-MM-DD")}-${moment(end_date).format("YYYY-MM-DD")}`;
              } else {
                file_name += "-All";
              }
              file_name += ".xlsx";
              a.download = file_name;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
            }
          });
      } catch (error) {
        alert("Connection error. Please try again.");
        EventEmitter.emit("export_loading", { value: false });
      }
      EventEmitter.emit("export_loading", { value: false });
    }
    EventEmitter.emit("export_loading", { value: false });
  };

  const sortData = async (val, defaultType) => {
    setSortLoading(true);
    var sort_field_data = {
      order_number: { type: "desc", value: false },
      date: { type: "desc", value: false },
      supplier: { type: "desc", value: false },
      customer: { type: "desc", value: false },
      status: { type: "desc", value: false },
      amount: { type: "desc", value: false },
      match_invoice: { type: "desc", value: false }
    };

    var sort_data = sortFieldRef.current[val];
    if (sort_data) {
      var value = sort_data.value;
      if (sort_data.value === false) {
        value = true;
      }

      if (value === true) {
        var sort_type = "";
        if (defaultType) {
          sort_type = defaultType;
        } else {
          if (sort_data.type === "desc") {
            sort_type = "asc";
          } else {
            sort_type = "desc";
          }
        }

        sort_field_data[val].value = true;
        sort_field_data[val].type = sort_type;

        var sort_data = localStorage.sort_data;
        if (sort_data) {
          sort_data = JSON.parse(sort_data);
          sort_data.order = sort_field_data;
        } else {
          sort_data = { order: sort_field_data };
        }
        localStorage.setItem("sort_data", JSON.stringify(sort_data));
        setSortField(sort_field_data);
      }
    }
    await loadOrders();
  };

  const getTableHeader = () => {
    let header_data = showPOAccount ? headerData : headerDataWithNoAccount;
    var list = [];

    header_data.map((value, index) => {
      const tableKey = value.id;
      const isSortData = tableKey !== "icon" && tableKey !== "statusIcon";

      list.push(
        <div key={index} className={`child header-sort ${tableKey === 'status' ? 'status-header-style' : ''}`} style={{ "--width": value.width_table + "%", cursor: isSortData ? "pointer" : "auto" }} onClick={() => sortData(tableKey)}>
          <div className='text'>
            <Typography noWrap component="div">
              <b>
                <OverflowTip>{value.name}</OverflowTip>
              </b>
            </Typography>
          </div>
          { isSortData ? (
            <div style={{ position: "relative" }}>
              {sortFieldRef.current && sortFieldRef.current[tableKey] && sortFieldRef.current[tableKey].value === true ? (
                sortFieldRef.current[tableKey].type === "asc" ? (
                  <SortRoundedIcon className={classes.ascSort} />
                ) : (
                  <SortRoundedIcon className={classes.descSort} />
                )
              ) : (
                ""
              )}
            </div>
          ) : ''}
        </div>
      )
    })
    return (<div className='orders-table-header'>{list}</div>)
  }

  const handleClickOrder = (orderID) => {
    if (searchRef.current) {
      localStorage.setItem("search_word", JSON.stringify(searchRef.current));
    }
    navigate(`/purchase-orders/${orderID}`, { state: { page: page, limit: pageNumberRef.current } });
  }

  const tableData = (index, data, totalOrder, statusIconPath, header_data) => {
    return (
      <div key={`order-item-${index}`} className='list' onClick={() => handleClickOrder(data.id)}>
        <div className='child' style={{ "--width": header_data[0].width_table + "%", display: 'flex', justifyContent: 'center' }}>
          <img src={"/static/images/icons/po-icon.svg"} />
        </div>
        <div className='child' style={{ "--width": header_data[1].width_table + "%" }}>
          <div className='text' style={{ color: '#000000', fontSize: '17px'}}>
            <Typography noWrap component="div">
                <OverflowTip>P{data.order_number}</OverflowTip>
            </Typography>
          </div>
        </div>
        <div className='child' style={{ "--width": header_data[2].width_table + "%" }}>
          <div className='text'>
            <Typography noWrap component="div">
              <OverflowTip>{data.order_date ? moment(data.order_date).format('DD/MM/YYYY') : ''}</OverflowTip>
            </Typography>
          </div>
        </div>
        <div className='child' style={{ "--width": header_data[3].width_table + "%" }}>
          <div className='text'>
            <Typography noWrap component="div" style={{ paddingRight: '20px' }}>
              <OverflowTip>{data.supplier !== null ? data.supplier.name : ''}</OverflowTip>
            </Typography>
          </div>
        </div>
        {showPOAccount ?
          <div className='child' style={{ "--width": header_data[4].width_table + "%" }}>
            <div className='text'>
              <Typography noWrap component="div" style={{ paddingRight: '20px' }}>
                <OverflowTip>{data.farm_code !== null ? data.farm_code.name : ''}</OverflowTip>
              </Typography>
            </div>
          </div>
        : ''}
        <div className='child' style={{ "--width": header_data[showPOAccount ? 5 : 4].width_table + "%" }}>
          <div className='text' style={{ display: 'flex', width: '100%' }}>
            <div style={{ width: '80%' }}>
              <Typography noWrap component="div">
                <OverflowTip>{isNaN(totalOrder) ? 0 : '$' + formatNumber(totalOrder.toFixed(2))}</OverflowTip>
              </Typography>
            </div>
            <div style={{ width: '20%', display: 'flex', alignItems: 'center' }}>
              {data.invoices ? (
                data.invoices.length > 0 ? (
                  <InsertDriveFileIcon
                    style={{
                    color: 'white',
                    backgroundColor: 'rgba(60, 60, 67, 0.6)',
                    padding: '3px',
                    borderRadius: '50%',
                    fontSize: '18px', cursor: 'pointer'
                  }} />
                ) : null
              ) : null}
            </div>
          </div>
        </div>
        <div className='child status-body-style' style={{ "--width": header_data[showPOAccount ? 6 : 5].width_table + "%", display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <div className='text' style={{ color: '#000000', paddingRight: '20px'}}>
            <Typography noWrap component="div">
              <OverflowTip><span style={{ textTransform: 'capitalize' }}>{data.status === 'awaiting_approval' ? 'Awaiting Approval' : data.status}</span></OverflowTip>
            </Typography>
          </div>
          <img src={statusIconPath} />
        </div>
      </div>
    )
  }

  const getTableBody = () => {
    var list = [];
    let header_data = showPOAccount ? headerData : headerDataWithNoAccount;

    if(orders) {
      if (orders.length > 0) {
        ordersRef.current.map((data, index) => {
          let totalOrder = data.total_order;
          let orderStatus = data.status;
          let statusIconPath = '';

          switch (orderStatus) {
            case 'draft':
              statusIconPath = "/static/images/icons/draft.svg";
              break;
            case 'completed':
              statusIconPath = "/static/images/icons/complete.svg";
              break;
            case 'sent':
              statusIconPath = "/static/images/icons/sent.svg";
              break;
            case 'cancelled':
              statusIconPath = "/static/images/icons/cancelled.svg";
              break;
            case 'awaiting_approval':
              statusIconPath = "/static/images/icons/awaiting_approval.svg";
              break;
            case 'rejected':
              statusIconPath = "/static/images/icons/rejected.svg";
              break;
            default:
              statusIconPath = "";
          }

          list.push(tableData(index, data, totalOrder, statusIconPath, header_data));
        })
      } else {
        if (!ordersLoading) {
          if(!ordersLoading) {
            list.push (
              <Grid container direction="column" justifyContent="center" alignItems="center"
                style={{ opacity: "0.2", paddingTop: "6pc" }}>
                <Grid item xs={12} sm={12} lg={12}>
                  <img alt="No Data Found" height="230" width="230" src="/static/images/folder.png?v=0.0.1" />
                  <Typography variant="h3" style={{ textAlign: "end" }}>
                    No Data Found
                  </Typography>
                </Grid>
              </Grid>
            )
          } else {
            list.push(
              ''
            );
          }
        }
      }
    } else {
      if(!ordersLoading) {
        list.push (
          <Grid key='no-data-found' container direction="column" justifyContent="center" alignItems="center"
            style={{ opacity: "0.2", paddingTop: "6pc" }}>
            <Grid item xs={12} sm={12} lg={12}>
              <img alt="No Data Found" height="230" width="230" src="/static/images/folder.png?v=0.0.1" />
              <Typography variant="h3" style={{ textAlign: "end" }}>
                No Data Found
              </Typography>
            </Grid>
          </Grid>
        )
      } else {
        list.push(
          <CircularProgress key='load-progress-orders' className={classes.circular} />
        );
      }
    }

    return (
      <div className='orders-table-body'>
        {list}
      </div>
    );
  }

  return (
    <Page className={classes.root} title="Purchase Orders" style={{ paddingTop: "19px", paddingBottom: "99px" }}>
      <Container maxWidth={false} style={{ padding: "0px 16px" }}>
        {getTableHeader()}

        {sortLoadingRef.current ? (
          <Grid item xs={12}>
            <div className={classes.linearProgress}
              style={{ marginTop: "7px", marginLeft: "7px", marginRight: "7px" }}>
              <LinearProgress style={{ height: "2px" }} />
            </div>
          </Grid>
        ) : (
          ""
        )}

        {getTableBody()}

        {orders ? (
          orders.length > 0 ? (
            <Grid item xs={12} style={{ textAlign: "right", marginTop: "10px", fontFamily: "SF Pro" }}>
              <span style={{ paddingRight: "20px" }}>Rows per page:</span>
              <span style={{ paddingRight: "20px" }}>
                <TextField id="standard-select-currency" select value={pageNumber} onChange={handleChange}>
                  {pageOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </span>

              <span id="pagination_number" style={{ paddingRight: "20px" }}>
                {pageNumberRef.current * (page - 1) + 1} - {pageNumberRef.current * page > totalPage ? totalPage : pageNumberRef.current * page} of{" "}
                {totalPage}
              </span>

              <Button
                id="prev_btn"
                variant="outlined"
                size="small"
                disabled={page === 1 || loadingPagination}
                className={classes.buttonLeft}
                onClick={() => pageChange(-1)}
              >
                <ArrowBackIosRoundedIcon />
              </Button>

              <Button
                id="next_btn"
                variant="outlined"
                size="small"
                className={classes.buttonRight}
                disabled={page >= Math.ceil(totalPage / pageNumberRef.current) || loadingPagination}
                onClick={() => pageChange(1)}
              >
                <ArrowForwardIosRoundedIcon />
              </Button>
            </Grid>
          ) : ("")
        ) : ("")}
      </Container>
    </Page>
  )
}

export default AllPurchaseOrders