import React from 'react';
import { useStyles } from './style.js';
import { OverflowTip } from "src/components/OverflowTip";
import {
  Grid,
  Typography,
} from "@material-ui/core";

const OrderItemsNotDraftView = ({
  dataItem,
  index,
  isLastItem,
  itemTotalCount,
  formatNumber
}) => {
  const classes = useStyles();

  return (
    <div>
      <Grid key={index} container spacing={0} justifyContent="space-around" style={{ marginBottom: isLastItem ? '0px' : '5px', background: 'white', padding: '0px 16px', borderRadius: '10px', height: '44px', display: 'flex', alignItems: 'center' }}>
        <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridPaddingRight}>
          <Typography noWrap component="div" className={classes.typographyStyle2}>
            <OverflowTip>{dataItem.quantity ? dataItem.quantity : ''}</OverflowTip>
          </Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
          <Typography noWrap component="div" className={classes.typographyStyle2}>
            <OverflowTip>{dataItem.internal_code ? dataItem.internal_code : ''}</OverflowTip>
          </Typography>
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridPaddingRight}>
          <Typography noWrap component="div" className={classes.typographyStyle2}>
            <OverflowTip>{dataItem.description ? dataItem.description : ''}</OverflowTip>
          </Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
          <Typography noWrap component="div" className={classes.typographyStyle2}>
            <OverflowTip>{isNaN(dataItem.unit_price) ? 0 : '$' + formatNumber((Math.round(dataItem.unit_price * 100) / 100).toFixed(2))}</OverflowTip>
          </Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
          <Typography noWrap component="div" className={classes.typographyStyle2}>
            <OverflowTip>{dataItem.item_discount ? `${dataItem.item_discount}%` : '0%'}</OverflowTip>
          </Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <Typography noWrap component="div" className={classes.typographyStyle2} style={{ textAlign: 'right' }}>
            <OverflowTip>{itemTotalCount(dataItem.quantity, dataItem.unit_price, dataItem.item_discount)}</OverflowTip>
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default OrderItemsNotDraftView