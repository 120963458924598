import { makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Fab
} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'auto',
    padding: "0px !important"
  },
  title: {
    marginBottom: '5px'
  },
  circular: {
    color: '#5bfa17',
    marginLeft: '45%'
  },
  listitem: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px !important',
  },
  buttonLeft: {
    backgroundColor: 'white',
    padding: 0,
    width: '60px',
    borderRadius: '24px 0 0 24px',
    borderRight: 0,
    boxShadow: '0 0 black',
    border: 0,
    height: 30,
  },
  buttonRight: {
    backgroundColor: 'white',
    padding: 0,
    width: '60px',
    borderRadius: '0 24px 24px 0',
    boxShadow: '0 0 black',
    border: 0,
    height: 30,
  },
  headerSort: {
    display: "flex",
    overflowX: "hidden",
    cursor: "pointer"
  },
  ascSort: {
    color: "#516573",
    fontSize: "20px",
    position: "absolute",
    marginTop: "1px",
    marginLeft: "3px",
    transform: "rotateX(180deg)"
  },
  descSort: {
    color: "#516573",
    fontSize: "20px",
    position: "absolute",
    marginTop: "1px",
    marginLeft: "3px",
  }
}));

export const AddButton = withStyles((theme) => ({
  root: {
    backgroundColor: '#3399FF',
    width: '30px',
    height: '30px',
    minHeight: '30px',
    boxShadow: '0 0 black',
    // left: '10%',
  },
}))(Fab);

export const SubmitButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#22a033',
    },
  },
})(Button);

export const CancelButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#e0e0e0de',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#bdbdbdde',
    },
  },
})(Button);

export const RejectButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#FFDBD9'),
    backgroundColor: '#FFDBD9',
    borderRadius: '10px',
    height: '25px',
    width: '100px',
    display: 'inline-grid',
    marginRight: '6px',
    padding: "0px",
    '&:hover': {
      backgroundColor: '#FFDBD9',
    },
  },
}))(Button);

export const ApproveButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#D0F2D4'),
    backgroundColor: '#D0F2D4',
    borderRadius: '10px',
    height: '25px',
    width: '100px',
    display: 'inline-grid',
    padding: "0px",
    '&:hover': {
      backgroundColor: '#D0F2D4',
    },
  },
}))(Button);

export const UndoButton = withStyles((theme) => ({
  root: {
    borderRadius: '24px',
    backgroundColor: 'rgb(255 240 221)',
    height: '25px',
    width: '145px',
    '&:hover': {
      backgroundColor: 'rgb(255 240 221)',
    },
  },
}))(Button);